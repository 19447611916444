import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import {Button, DialogActions, DialogContent} from "@material-ui/core";
import Box from "@mui/material/Box";

const ImagePreviewDialog = ({imagePath}: {imagePath: string}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box sx={{ m: 2 }} />

            <Button
                size="small"
                variant={"contained"}
                fullWidth
                onClick={handleClickOpen}
            >
                Show seat configuration
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <img
                        src={imagePath}
                        alt="Base64 Example"
                        style={{
                            display: "block",
                            width: '100%'
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleClose}
                        autoFocus
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ImagePreviewDialog;
