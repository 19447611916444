const locales_en = {
    /*General*/
    "general_building-singular": "Building",
    "general_building-plural": "Buildings",
    "general_room-plan-singular": "Room plan",
    "general_room-plan-plural": "Room plans",
    "general_room-singular": "Room",
    "general_room-plural": "Rooms",
    "general_neighborhood-singular": "Neighborhood",
    "general_neighborhood-plural": "Neighborhoods",
    "general_booking-singular": "Booking",
    "general_booking-plural": "Bookings",
    "general_time": "Time",
    "selected": "selected",
    "general_select_option_none": "none",
    "general_visitors": "Visitors",
    "general_meetroom_seatconf_col": "Seat configuration",

    /*Room plan*/
    "all_room-plans": "All roomplans",
    "general_meeting-room-singular": "Meeting room",
    "general_meeting-room-plural": "Meeting rooms",

    "button_manage_buildings-title": "Manage buildings",
    "button_my_settings-title": "My settings",
    "signout": "Sign out",
    "my_settings-dialog-title": "My settings",
    "notification-dialog-title": "Notification settings",
    "notification-dialog-hint": "Choose which actions you want to receive email notifications for",
    "notification-option-1": "Receive my own seat bookings as an Outlook appointment",
    "notification-option-2": "Receive external bookings made by me as an Outlook appointment",
    "notification-option-3": "Receive meeting room bookings as Outlook appointment",
    "my_settings-room_plan-title": "Default room plan",
    "my_settings-auto-default": "Determine automatically",
    "my_settings-default-warning-1": "Your currently selected preference ",
    "my_settings-default-warning-2": " is not accessible right now. Your preference will be restored when the room plan becomes accessible again. If you save a new preference your current preference will be permanentely overwritten.",
    "my_settings-consent_title": "Consent",
    "my_settings-hint_text": "Your name will be displayed to other users when you book a seat. Do you agree to this?",
    "my_settings-consent_text": "I agree",
    "my_settings-no_consent_text": "I do not agree",
    "my_settings-no_consent_deletion_title": "Deleting seat bookings",
    "my_settings-no_consent_deletion_text": "This will delete all your existing seat bookings.",
    "room_management_dialog-title": "Manage room plans",
    "room_public-active": "Public",
    "seat_management_dialog-title": "Manage seats",
    "apply_to_selected-button-text": "apply to selected",
    "room_hint_management_dialog_title": "Manage rooms",
    "seat_booking_error_no_rooms_configured": "There are currently no room plans configured. Please contact your office manager.",
    "seat_booking-no_consent_text": "To book a place, you must agree to your name being displayed to other users. To give your consent, go to \"My Settings\".",
    "org_unit_no_org_selected": "Choose an org-unit",
    "room_plan_no_room_selected": "Select a room plan",
    "room_plan_no_room_available": "No room plan available",
    "room_name-column": "Name",
    "room-name": "Room name",
    "room_building-column": "Building",
    "room_plan-column": "SVG",
    "scaleFactor-column": "Scaling",
    "room_active-column": "Active",
    "room_default-column": "Default",
    "room_time-active": "By time",
    "meeting-room-seatconf-name": "Name",
    "meeting-room-seatconf-daysadvance": "Days",
    "meeting-room-seatconf-image": "Image",
    "button_upload-text": "Upload SVG",
    "button_svg_delete-text": "Delete SVG",
    "button_create_room-text": "Create",
    "button_close_room_management_dialog-text": "Close",
    "message_error_file_type": "File is invalid!",
    "message_success_uploaded_file": "File has been uploaded!",
    "message_error_create_room": "A room with the same name and the same org-unit already exists in the system.",
    "my_bookings_mngmt_dialog-title": "My bookings",
    "docking_mngmnt_dialog-title": "Manage docking stations",
    "monitor_mngmnt_dialog-title": "Manage monitors",
    "equipment_mngmnt_dialog-title": "Manage equipment",
    "booking_limitation_dialog_title": "Advanced configuration",
    "seat_configuration_dialog-title": "Configure seat",
    "seat_config_mass_apply_confirm_overwrite": "Do you really want to overwrite existing seat configurations?",
    "bookings_for_these_seats_will_be_deleted": "Bookings for these seats will be deleted:",
    "seat_config_apply_confirm_bookings_deletion": "Changing this configuration will delete all seat bookings for the seat. Are you sure?",
    "docking_station-column": "Docking stations",
    "monitor-column": "Monitors",
    "height-adjustable-column": "Height-adjustable",
    "height-adjustable-checkbox-label": "Table is height-adjustable",
    "seat-is-bookable-checkbox-label": "Seat is bookable",
    "meetingroom-is-bookable-checkbox-label": "Meeting Room is bookable",
    "select_monitor": "Select a monitor",
    "select_docking_station": "Select a docking station",
    "inv_mngmnt_close_dialog_button-text": "Close",
    "inv_mngmnt_add_button-text": "Add",
    "inv_mngmnt_edit_button-text": "Edit",
    "inv_mngmnt_delete_button-text": "Delete",
    "equipment_close_dialog_button-text": "Close",
    "equipment_mngmnt_add_button-text": "Add",
    "equipment_mngmnt_close_dialog_button-text": "Close",
    "equipment_mngmnt_edit_button-text": "Edit",
    "equipment_mngmnt_delete_button-text": "Delete",
    "confirm_dialog_ok_button-text": "Confirm",
    "confirm_dialog_cancel_button-text": "Cancel",
    "seats": "Seats",
    "rooms": "Rooms",
    "seat_info_dockingstations_title": "Docking stations",
    "seat_info_monitors_title": "Monitors",
    "button_save": "Save",
    "button_close": "Close",
    "seat_name-column": "Seat name",
    "bookable-column": "Bookable",
    "configure_seat": "Configure seat",
    "room_name_column": "Room",
    "room_note_text_column": "Note text",
    "inv_mngmnt_error_create_inv": "An inventory with this name already exists in the system.",
    "equipment_mngmnt_error_create_equipment": "An equipment with this name already exists in the system.",
    "equipment_mngmnt_error_delete_equipment": "An error occured while deleting an equipment.",
    "roomplan_removeseat_or_book_for_other_day_title": "This seat is already yours on this day.",
    "roomplan_removemeetingroom_or_book_for_other_day_title": "This room already belongs to you on this day.",
    "roomplan_removeseat_or_book_for_other_day_infotext": "Would you like to delete your booking or book it for more days?",
    "button_delete_booking": "Delete booking",
    "button_book_other_days": "Book other days",
    "open_another_booking_dialog": "Book other days/times",
    "multibookingdialog_calendar_title": "Calendar",
    "multibookingdialog_textfield_label_booking_for": "Booking for",
    "multibookingdialog_dropdown_label_booking_for": "Booking for",
    "multibookingdialog_legend_title": "Legend",
    "multibookingdialog_legend_own_booking": "Seat booked by me",
    "multibookingdialog_legend_no_booking": "Seat available",
    "multibookingdialog_meetingroom_legend_own_booking": "Room booked through me",
    "multibookingdialog_meetingroom_legend_no_booking": "Room available",
    "multibookingdialog_legend_booking_available": "Only certain time slots available",
    "multibookingdialog_legend_other_user": "Seat is not available",
    "multibookingdialog_meetingroom_legend_other_user": "Room not available",
    "multibookingdialog_legend_marked": "Days marked for booking",
    "multibookingdialog_title_normal": "Book {{type}}",
    "multibookingdialog_title_booking_type_meeting_room": "meeting room",
    "multibookingdialog_title_booking_type_seat": "seat",
    "multibookingdialog_error_booking_not_complete": "Not all selected days could be booked. The selection of days has been adjusted accordingly. Please try again.",
    "multibookingdialog_error_seat_already_taken": "The seat is already taken on this day.",
    "multibookingdialog_meetingroom_error_seat_already_taken": "The room is booked in this time window.",
    "multibookingdialog_error_max_booking_days_exceeded": "Pre-booking is possible only until ",
    "multibookingdialog_error_max_bookable_days_": "Maximum of bookable days reached. Seat booking on other days is no longer possible.",
    "multibookingdialog_meetingroom_error_max_bookable_days_": "You reached the maximum number of bookable days. Booking rooms on other days won't be possible.",
    "multibookingdialog_dropdown_booking_for_me": "Booking for myself",
    "multibookingdialog_dropdown_booking_for_others": "Booking for others",
    "multibookingdialog_error_parallel_booking": "Another user has just booked the seat on %s. The day can no longer be booked.",
    "multibookingdialog_error_parallel_bookings": "Another user has just booked the seat on days %s. The days can no longer be booked.",
    "multibookingdialog_meetingroom_error_parallel_booking": "Another user has just booked the room on %s. The Room is not bookable anymore on this day.",
    "multibookingdialog_meetingroom_error_parallel_bookings": "Another user has just booked the room on the following days %s. The room is not bookable on those days anymore.",
    "multibookingdialog_time_window_title": "Free time slots",
    "multibookingdialog_meetingroom_settings_title": "Settings",
    "multibookingdialog_meetingroom_settings_meeting_name": "Meeting name",
    "multibookingdialog_meetingroom_settings_participant_number": "Number of participants",
    "multibookingdialog_meetingroom_settings_participant_number_recommendation": "The maximum recommended number of participants for this room is {{capacity}}",
    "multibookingdialog_time_window_no_common_time_slot": "No common available time slot on the selected days.",
    "multibookingdialog_error_time_slot_not_bookable": "Selected time slot is not bookable.",
    "multibookingdialog_error_time_slot_not_bookable_endtime_is_in_past": "End time cannot be in the past.",
    "multibookingdialog_error_time_slot_not_bookable_endtime_is_in_past_cannot_edit": "Meeting in the past cannot be edited.",
    "button_book_other_days_times": "Book other days/time",
    "meetingroom_booking_type_internal": "Internal",
    "meetingroom_booking_type_external": "External",
    "meetingroom_booking_type_vip": "VIP",
    "book": "Book",
    "cancel": "Cancel",
    "owner-column": "Seat owner",
    "select_user": "Select user",
    "no_owner": "No owner",
    "no_user": "No user",
    "apply": "Apply",
    "seat-adjustable-height-hover": "height-adjustable",
    "expand-seat-filters": "Expand seat filters",
    "collapse-seat-filters": "Collapse seat filters",
    "delete_room_dialog_content": "Are you sure you want to delete it?",
    "delete_dialog_content": "Are you sure you want to delete it?",
    "jsLocalDateProperty": "en-GB",
    "date": "Date",
    "delete": "Delete",
    "edit": "Edit",
    "roomplan": "Room plan",
    "seat": "Seat",
    "find_room_label": "Find room",
    "no_seats_available": "No seats available",
    "anonymous":"anonymous",
    /* Support Dialog */
    "support_dialog-title": "Support",
    "support_dialog-text": "For support please write an e-mail to",
    /*Own Bookings*/
    "own_bookings_management_dialog_title": "My bookings",
    "own_bookings_management_alert_dialog_title": "Are you sure you want to delete selected bookings?",
    "own_bookings_management_alert_dialog_amount_bookings": "Number of selected bookings",
    "timeWindow": "Time slot",
    /*RoomManager*/
    "rm_showUploadConfirmationDialog_cancel_button-text": "Cancel",
    "rm_showUploadConfirmationDialog-text-seats": "The following seats are not available in the new room plan, bookings for these seats will be lost: ",
    "rm_showUploadConfirmationDialog-text-meetingRooms": "The following meeting rooms are not available in the new room plan, bookings for these meeting rooms will be lost: ",
    "rm_showUploadConfirmationDialog-text-seats-update": "The following seats will be updated: ",
    "rm_showUploadConfirmationDialog-text-meetingRooms-update": "The following meeting rooms will be updated: ",
    "rm_showUploadConfirmationDialog_refresh_button-text": "Update",
    "rm_uploadInconsistentDialog-content": "Following neighborhoods don't exist: ",
    "rm_uploadInconsistentDialog-close-button": "Close",
    /*DailyBookingsDisplay*/
    "daily_bookings_table_bookingname_column": "Name",
    "daily_bookings_table_room_column": "Room plan",
    "daily_bookings_table_seat_column": "Seat",
    "daily_bookings_table_orgunit_column": "Org. unit",
    "daily_bookings_empty_list_message": "There are no bookings on the selected day",
    "daily_bookings_count_display_text": "Bookings: ",
    "booking-error_title": "Booking error",
    "default_error": "Something went wrong with your request.",
    "booking_error": "Something went wrong with your booking.",
    "seat_not_bookable_error": "Seat is currently not bookable",
    "error_collection": "Occurred errors",
    "error_message": "Error message",
    "custom_error_message": "Custom message",
    "stack_trace": "Stack Trace",
    "max_bookable_days": "Max. number of bookable days",
    "max_lead_time": "Max. lead time in days",
    "Booking_day": "Bookings on",
    "minutes": "minutes",
    "message_disable_time_bookings": "There are seats that have multiple bookings per day. The earliest booking on each seat will be kept and the rest of the bookings will be deleted. In total %number bookings will be deleted. Confirm the deletion?",
    "message_disable_time_booking": "There are seats that have multiple bookings per day. The earliest booking on each seat will be kept and the rest of the bookings will be deleted. In total one booking will be deleted. Confirm the deletion?",
    'error-boundary-default-error': 'Something went wrong.',
    'svg_upload_aborted_title': "SVG upload aborted",
    'svg_upload_aborted_text': "SVG upload aborted, please try again later.",
    /*Neighborhoods*/
    "neighborhood_management_dialog-title": "Manage neighborhoods",
    "neighborhoods": "Neighborhoods",
    "no_neighborhood_selected": "Select a neighborhood",
    "all_neighborhoods": "All neighborhoods",
    "neighborhood_restricted_label": "Restricted",
    "neighborhood_access_rights": "Access rights",
    "neighborhood_admin_rights": "Admin rights",
    "not_materna_employee": "You can't use Sit2Gether, because it hasn't been rolled out for your company just yet",
    "neighborhood_confirm_overwrite": "Bookings from the users who lost access due to the new neighborhoods settings will be deleted. Do you want to continue?",
    "neighborhood_name_collision_title": "Neighborhood name already exists",
    "neighborhood_name_collision_text": "Neighborhood names must be unique within the room plan.",
    "neighborhood_name_empty_title": "Neighborhood name is empty",
    "neighborhood_name_empty_text": "Neighborhood name can not be empty.",
    "neighborhood_id_too_long_title": "Neighborhood id is too long.",
    "neighborhood_id_too_long_text": "Neighborhood id is too long, id: .",
    /*Meeting Rooms*/
    "meeting_rooms_management_dialog-title": "Manage Meeting Rooms",
    "button_meeting_rooms": "Meeting Rooms",
    "meeting_room_table_name_column": "Name",
    "meeting_room_table_id_column": "ID",
    "meeting_room_table_capacity_column": "Capacity",
    "meeting_room_table_type_column": "Type",
    "meeting_room_table_equipment_column": "Equipment",
    "meeting_room_table_bookable_column": "Bookable",
    "configure_meeting_room": "Configure Meeting Room",
    "meeting_room_manage_rights": "Manage booking rights",
    "meeting_room_name_tooltip": "Name must be unique and between 1 to 60 characters.",
    "meeting_room_capacity": "Capacity",
    "meeting_room_manage_type": "Type",
    "meeting_room_capacity_tooltip": "Capacity must be a number between 0 to 200.",
    "meeting_room_name_exists_error_text": "Meeting room name already exists",
    "meeting_room_invalid_name_error_text": "Name must be between 1 to 60 characters.",
    "meeting_room_invalid_capacity_error_text": "Capacity must be a number between 0 to 200.",
    "internal_server_error_text": "An internal server error occurred.",
    "unknown_error_text": "An unknown server error occurred.",
    "meeting_room_table_image_column": "Image ",
    "meeting_room_table_days_in_advance_column": "Book seat configuration in advance",


    /*Room Manager Component*/
    "building_mngmnt_dialog-title": "Manage Buildings",
    "building-column": "Buildings",
    "building-create-button": "Add",
    "building-manage-button": "Manage",
    "building-delete-button": "Delete",
    "building-close-button": "Close",
    "building-error-create-alert-title": "Building already exists",
    "building-manage-title": "Manage Building",
    "building-floor-column": "Floor",
    "building-org-unit-column": "Org. unit",
    "building-room-plan-name-column": "Room plan name",
    "building-remove-button": "Remove",
    "building-assign-button": "Assign",
    "building-available-room-plans-title": "Available room plans",
    "building-room_active-column": "Active",
    "building-room_public-active": "Public",
    "building-room-with-no-org-unit": "Rooms with no org. unit",
    "building-name-of-building": "Name of Building",
    "seat-config-email": "Seat configuration email",
    "seat-config-days-in-advance-message": "Changes of the seat configuration must be ordered at least {{days}} days in advance",
    "building-add-text": "Add Building",
    "building-mange-role-selector": "Manage editing rights",
    "room_plan_manage_building": "Building",
    /*MeetingRoomBookingManager*/
    "meeting_room_booking_manager_dialog-title": "Meeting room bookings",
    "creator-of-booking": "Booker",
    "meeting-room-name": "Room",
    "neighborhood-name": "Neighborhood",
    "room-level": "Floor",
    "start-time": "From",
    "end-time": "To",
    "edit-meeting-room-bookings": "Edit meeting room booking",
    "create-meeting-room-bookings": "Create meeting room booking",
    "meeting-name": "Meeting name",
    "number-of-guests": "Number of participants",
    "booking-type": "Booking type",
    "edit_button-text": "Edit",
    "close_button-text": "Close",
    "meeting_room_type": "Type",
    "ground_floor": "Ground floor",
    "meetingroom_type_classroom": "Classroom",
    "meetingroom_type_internal": "Internal",
    "meetingroom_type_neighborhood": "Neighborhood",
    "meetingroom_type_visitors_center": "Visitors center",

    /* MeetingRoomEquipment */
    "general_equipment-singular": "Equipment",

    "alt_first_name_multiple_bookings_same_seat": "Multiple",
    /* Own Booking Manager */
    "meeting_rooms": "Meeting Rooms",
    "time": "Time",
    "number_of_participants": "Participants",
    "meeting_name": "Meeting name",

    "entries_per_page": "Entries per page",
    "pagination_of": "of",

    "update_seat_configs_partially_failed": "Some Einige Sitzplätze konnten nicht konfiguriert werden.",

    /* Mobile specific text */
    "mobile_show_filter": "Filter",
    "mobile_hide_filter": "Minimize",

    /* room finder */
    "roomfinder_no_options_text": "No Options",

    /* Meeting Room Booking Manager Filter */
    "Meeting_Room_Bookings_Neighborhood_Filter_Label": "Neighborhood meeting rooms",
    "Meeting_Room_Bookings_Neighborhood_Filter_All_Neighborhoods": "From all neighborhoods",
    "Meeting_Room_Bookings_Neighborhood_Filter_My_Neighborhood": "From my neighborhood",

    /* Meeting Room Booking Manager Type Filter*/
    "Meeting_Room_Bookings_Type_Filter_Label": "Type",
    "Meeting_Room_Bookings_Type_Filter_All": "All types",

    /* Meeting Room Booking Manager Filter Mobile*/
    "meeting_room_bookings_show_filter": "Show filter options",
    "meeting_room_bookings_hide_filter": "Hide filter options",

    /*Meeting Room Booking Manager Min Capacity Filter*/
    "Meeting_Room_Bookings_Min_Cap_Filter_Label": "Minimum capacity",

    /*
    Header Notification
    */
    "save-button-text": "Save",
    "cancel-button-text": "Cancel",
    "close-button-text": "Close",
    "user-info-text-menu-item": "User Info Text",
    "user-info-text-header": "Announcement",
    "user-info-text-importance": "Important",

    // Error messages:
    "error_loading_bookings": "Error loading bookings",
    "error_deleting_bookings": "Error deleting bookings",

}
export default locales_en;
