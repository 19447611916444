/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type RoomPlanUploadActionsInput = {
  create?: ActionCreateInput | null,
  update?: ActionUpdateInput | null,
  delete?: ActionDeleteInput | null,
};

export type ActionCreateInput = {
  hints?: Array< ActionHintInput | null > | null,
  neighborhoods?: Array< ActionNeighborhoodInput | null > | null,
  meetingRooms?: Array< ActionMeetingRoomInput | null > | null,
  seats?: Array< ActionSeatConfigInput | null > | null,
};

export type ActionHintInput = {
  hintId: string,
  roomId: string,
  text?: string | null,
};

export type ActionNeighborhoodInput = {
  neighborhoodId: string,
  roomId: string,
  restricted?: boolean | null,
  roleIds?: Array< string > | null,
  name?: string | null,
  adminRoleIds?: Array< string > | null,
};

export type ActionMeetingRoomInput = {
  meetingRoomId: string,
  roomId: string,
  name?: string | null,
  nameLowerCased?: string | null,
  hintId?: string | null,
  type?: string | null,
  capacity?: number | null,
  roleIds?: Array< string | null > | null,
  neighborhoodId?: string | null,
  equipmentIds?: Array< string | null > | null,
  isBookable?: boolean | null,
};

export type ActionSeatConfigInput = {
  roomId: string,
  seatName: string,
  neighborhoodId?: string | null,
  seatType?: string | null,
  inventory?: Array< string | null > | null,
  owner?: string | null,
  isBookable?: boolean | null,
};

export type ActionUpdateInput = {
  hints?: Array< ActionHintInput | null > | null,
  neighborhoods?: Array< ActionNeighborhoodInput | null > | null,
  meetingRooms?: Array< ActionMeetingRoomInput | null > | null,
  seats?: Array< ActionSeatConfigInput | null > | null,
};

export type ActionDeleteInput = {
  hints?: Array< ActionHintInput | null > | null,
  neighborhoods?: Array< ActionNeighborhoodInput | null > | null,
  meetingRooms?: Array< ActionMeetingRoomInput | null > | null,
  seats?: Array< ActionSeatConfigInput | null > | null,
};

export type GenericMutationOutput = {
  __typename: "GenericMutationOutput",
  response?: string | null,
};

export type CreateBookingInput = {
  bookingId: string,
  date: string,
  seatId: string,
  roomId: string,
  bookerId: string,
  bookerName: string,
  bookerGivenName?: string | null,
  bookerFamilyName?: string | null,
  orgUnitId?: string | null,
  orgUnitAdmin?: string | null,
  bookingFor?: string | null,
  timeBegin?: string | null,
  timeEnd?: string | null,
};

export type UpdateSeatBookingInput = {
  bookingId: string,
  roomId: string,
  date: string,
  timeBegin?: string | null,
  timeEnd?: string | null,
};

export type DeleteBookingInput = {
  bookingId: string,
  date: string,
  bookerId: string,
  orgUnitId: string,
  roomId: string,
  seatId: string,
};

export type DeleteBookingsByUserInput = {
  bookerId: string,
};

export type CreateBuildingInput = {
  buildingId: string,
  buildingName: string,
  buildingNameLowerCased: string,
  roleIds?: Array< string > | null,
  seatConfEmail?: string | null,
};

export type Building = {
  __typename: "Building",
  buildingId: string,
  buildingName: string,
  rooms?: ModelRoomConnection | null,
  roleIds: Array< string >,
  buildingNameLowerCased: string,
  seatConfEmail?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelRoomConnection = {
  __typename: "ModelRoomConnection",
  items:  Array<Room | null >,
  nextToken?: string | null,
};

export type Room = {
  __typename: "Room",
  roomId: string,
  buildingId?: string | null,
  orgUnitId?: string | null,
  orgUnitAdmin?: string | null,
  name: string,
  nameLowerCased: string,
  roomPlanExisting?: boolean | null,
  roomPlanId?: string | null,
  roomPlanScaleFactor?: number | null,
  seats?: ModelSeatConfigConnection | null,
  isActive?: boolean | null,
  isTimeActive?: boolean | null,
  isBookingDisabled?: boolean | null,
  isDefault?: boolean | null,
  isPublic?: boolean | null,
  hasHints?: boolean | null,
  hasNeighborhood?: boolean | null,
  floor?: number | null,
  hasMeetingRooms?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelSeatConfigConnection = {
  __typename: "ModelSeatConfigConnection",
  items:  Array<SeatConfig | null >,
  nextToken?: string | null,
};

export type SeatConfig = {
  __typename: "SeatConfig",
  seatName: string,
  orgUnitAdmin?: string | null,
  roomId: string,
  seatType?: string | null,
  inventory: Array< string >,
  isSeatHeightAdjustable?: boolean | null,
  owner?: string | null,
  isOwnerRegistered?: boolean | null,
  neighborhoodId: string,
  isBookable?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBuildingInput = {
  buildingId: string,
  buildingName?: string | null,
  buildingNameLowerCased?: string | null,
  roleIds?: Array< string > | null,
  seatConfEmail?: string | null,
};

export type DeleteBuildingInput = {
  buildingId: string,
  buildingName: string,
  buildingNameLowerCased: string,
  roleIds?: Array< string > | null,
  seatConfEmail?: string | null,
};

export type CreateMeetingRoomInput = {
  meetingRoomId: string,
  roomId: string,
  name: string,
  nameLowerCased: string,
  hintId: string,
  type: string,
  capacity?: number | null,
  roleIds?: Array< string > | null,
  neighborhoodId: string,
  meetingRoomSeatConfIds?: Array< string | null > | null,
  equipmentIds?: Array< string > | null,
  isBookable: boolean,
};

export type MeetingRoom = {
  __typename: "MeetingRoom",
  meetingRoomId: string,
  roomId: string,
  name: string,
  nameLowerCased: string,
  hintId: string,
  type: string,
  capacity?: number | null,
  roleIds?: Array< string > | null,
  neighborhoodId: string,
  equipmentIds?: Array< string > | null,
  meetingRoomSeatConfIds?: Array< string > | null,
  isBookable: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMeetingRoomInput = {
  meetingRoomId: string,
  roomId: string,
  name: string,
  nameLowerCased: string,
  hintId: string,
  type: string,
  capacity?: number | null,
  roleIds?: Array< string > | null,
  neighborhoodId: string,
  meetingRoomSeatConfIds?: Array< string | null > | null,
  equipmentIds?: Array< string > | null,
  isBookable: boolean,
};

export type DeleteMeetingRoomInput = {
  meetingRoomId: string,
  roomId: string,
};

export type CreateRoomInput = {
  roomId: string,
  buildingId: string,
  orgUnitId?: string | null,
  orgUnitAdmin?: string | null,
  name: string,
  nameLowerCased: string,
  roomPlanExisting?: boolean | null,
  roomPlanId?: string | null,
  roomPlanScaleFactor?: number | null,
  isActive?: boolean | null,
  isTimeActive?: boolean | null,
  isBookingDisabled?: boolean | null,
  isDefault?: boolean | null,
  isPublic?: boolean | null,
  hasHints?: boolean | null,
  hasNeighborhood?: boolean | null,
  floor?: number | null,
  hasMeetingRooms?: boolean | null,
};

export type UpdateRoomInput = {
  roomId: string,
  buildingId?: string | null,
  orgUnitId?: string | null,
  orgUnitAdmin?: string | null,
  name?: string | null,
  nameLowerCased?: string | null,
  roomPlanExisting?: boolean | null,
  roomPlanId?: string | null,
  roomPlanScaleFactor?: number | null,
  isActive?: boolean | null,
  isTimeActive?: boolean | null,
  isBookingDisabled?: boolean | null,
  isDefault?: boolean | null,
  isPublic?: boolean | null,
  hasHints?: boolean | null,
  hasNeighborhood?: boolean | null,
  floor?: number | null,
  hasMeetingRooms?: boolean | null,
};

export type DeleteRoomInput = {
  roomId: string,
};

export type CreateSeatConfig = {
  roomId: string,
  seatName: string,
  neighborhoodId?: string | null,
  seatType?: string | null,
  inventory?: Array< string | null > | null,
  owner?: string | null,
  isBookable?: boolean | null,
};

export type DeleteSeatConfig = {
  roomId: string,
  seatName: string,
};

export type CreateMeetingRoomBookingInput = {
  bookingId: string,
  date: string,
  meetingRoomId: string,
  roomId: string,
  bookerId: string,
  bookerName: string,
  bookerGivenName?: string | null,
  bookerFamilyName?: string | null,
  orgUnitId?: string | null,
  timeBegin?: string | null,
  timeEnd?: string | null,
  meetingName?: string | null,
  roomCapacity?: number | null,
  participantNumber?: number | null,
  meetingType?: string | null,
  visitors: string,
  meetingRoomSeatConfId?: string | null,
};

export type UpdateMeetingRoomBookingInput = {
  bookingId: string,
  date: string,
  meetingRoomId: string,
  roomId: string,
  bookerId: string,
  bookerName: string,
  bookerGivenName?: string | null,
  bookerFamilyName?: string | null,
  orgUnitId: string,
  timeBegin?: string | null,
  timeEnd?: string | null,
  meetingName?: string | null,
  roomCapacity?: number | null,
  participantNumber?: number | null,
  meetingType?: string | null,
  visitors: string,
  meetingRoomSeatConfId?: string | null,
};

export type DeleteMeetingRoomBookingInput = {
  bookingId: string,
  date: string,
  bookerId: string,
  orgUnitId: string,
  meetingRoomId: string,
  roomId: string,
};

export type UpdateSeatConfigsInput = {
  roomId: string,
  seatName?: Array< string > | null,
  isSeatHeightAdjustable?: boolean | null,
  isBookable?: boolean | null,
  owner?: string | null,
  isOwnerRegistered?: boolean | null,
  inventory?: Array< string | null > | null,
  confirmed?: boolean | null,
  neighborhoodId?: string | null,
};

export type UpdateSeatConfigsOutput = {
  __typename: "UpdateSeatConfigsOutput",
  message?: UpdateSeatConfigsError | null,
  cause?: string | null,
};

export enum UpdateSeatConfigsError {
  OK = "OK",
  CONFIRMATION_REQUIRED = "CONFIRMATION_REQUIRED",
  INVALID_INPUT = "INVALID_INPUT",
  TRANSACTION_FAILED = "TRANSACTION_FAILED",
  NO_ACCESS = "NO_ACCESS",
  FAILURE = "FAILURE",
}


export type DeleteBookingsBySeatIdsInput = {
  seatIds?: Array< string | null > | null,
  roomOrgUnitAdmin?: string | null,
};

export type UpdateRestrictedNeighborhoodsInput = {
  neighborhoods?: Array< NeighborhoodInput | null > | null,
};

export type NeighborhoodInput = {
  neighborhoodId: string,
  roomId: string,
  restricted?: boolean | null,
  roleIds?: Array< string > | null,
  name?: string | null,
  adminRoleIds?: Array< string > | null,
};

export type UpdateRestrictedNeighborhoodsOutput = {
  __typename: "UpdateRestrictedNeighborhoodsOutput",
  statusCode?: number | null,
  message?: string | null,
};

export type InventoryInput = {
  inventoryId: string,
  orgUnitAdmin: string,
  orgUnitId: string,
  type: string,
  name: string,
  nameLowerCased: string,
};

export type Inventory = {
  __typename: "Inventory",
  inventoryId: string,
  orgUnitAdmin?: string | null,
  orgUnitId: string,
  type: string,
  name: string,
  nameLowerCased: string,
  createdAt: string,
  updatedAt: string,
};

export type DeleteInventoryInput = {
  inventoryId: string,
};

export type CreateNeighborhoodsInput = {
  neighborhoods?: Array< NeighborhoodInput | null > | null,
};

export type DeleteNeighborhoodsInput = {
  neighborhoods?: Array< DeleteNeighborhoodInput | null > | null,
};

export type DeleteNeighborhoodInput = {
  neighborhoodId: string,
  roomId: string,
};

export type CreateMeetingRoomEquipmentInput = {
  equipmentId: string,
  buildingId: string,
  name: string,
};

export type MeetingRoomEquipment = {
  __typename: "MeetingRoomEquipment",
  equipmentId: string,
  buildingId: string,
  name: string,
  nameLowerCased: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMeetingRoomEquipmentInput = {
  equipmentId: string,
  name: string,
};

export type DeleteMeetingRoomEquipmentInput = {
  equipmentId: string,
};

export type CreateMeetingRoomSeatConfigInput = {
  meetingRoomSeatConfId: string,
  buildingId: string,
  roomId: string,
  meetingRoomId: string,
  name: string,
  imageId?: string | null,
  daysInAdvance: number,
};

export type MeetingRoomSeatConfig = {
  __typename: "MeetingRoomSeatConfig",
  meetingRoomSeatConfId: string,
  buildingId: string,
  roomId: string,
  meetingRoomId: string,
  name: string,
  imageId?: string | null,
  daysInAdvance: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMeetingRoomSeatConfigInput = {
  meetingRoomSeatConfId: string,
  name: string,
  imageId?: string | null,
  daysInAdvance: number,
};

export type DeleteMeetingRoomSeatConfigInput = {
  meetingRoomSeatConfId: string,
};

export type ModelBuildingConditionInput = {
  buildingName?: ModelStringInput | null,
  roleIds?: ModelStringInput | null,
  buildingNameLowerCased?: ModelStringInput | null,
  seatConfEmail?: ModelStringInput | null,
  and?: Array< ModelBuildingConditionInput | null > | null,
  or?: Array< ModelBuildingConditionInput | null > | null,
  not?: ModelBuildingConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelRoomConditionInput = {
  buildingId?: ModelIDInput | null,
  orgUnitId?: ModelIDInput | null,
  orgUnitAdmin?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameLowerCased?: ModelStringInput | null,
  roomPlanExisting?: ModelBooleanInput | null,
  roomPlanId?: ModelStringInput | null,
  roomPlanScaleFactor?: ModelIntInput | null,
  isActive?: ModelBooleanInput | null,
  isTimeActive?: ModelBooleanInput | null,
  isBookingDisabled?: ModelBooleanInput | null,
  isDefault?: ModelBooleanInput | null,
  isPublic?: ModelBooleanInput | null,
  hasHints?: ModelBooleanInput | null,
  hasNeighborhood?: ModelBooleanInput | null,
  floor?: ModelIntInput | null,
  hasMeetingRooms?: ModelBooleanInput | null,
  and?: Array< ModelRoomConditionInput | null > | null,
  or?: Array< ModelRoomConditionInput | null > | null,
  not?: ModelRoomConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelMeetingRoomConditionInput = {
  name?: ModelStringInput | null,
  nameLowerCased?: ModelStringInput | null,
  hintId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  capacity?: ModelIntInput | null,
  roleIds?: ModelStringInput | null,
  neighborhoodId?: ModelIDInput | null,
  equipmentIds?: ModelStringInput | null,
  meetingRoomSeatConfIds?: ModelIDInput | null,
  isBookable?: ModelBooleanInput | null,
  and?: Array< ModelMeetingRoomConditionInput | null > | null,
  or?: Array< ModelMeetingRoomConditionInput | null > | null,
  not?: ModelMeetingRoomConditionInput | null,
};

export type CreateNeighborhoodInput = {
  neighborhoodId: string,
  roomId: string,
  restricted?: boolean | null,
  roleIds?: Array< string > | null,
  name?: string | null,
  adminRoleIds?: Array< string > | null,
};

export type ModelNeighborhoodConditionInput = {
  restricted?: ModelBooleanInput | null,
  roleIds?: ModelStringInput | null,
  name?: ModelStringInput | null,
  adminRoleIds?: ModelStringInput | null,
  and?: Array< ModelNeighborhoodConditionInput | null > | null,
  or?: Array< ModelNeighborhoodConditionInput | null > | null,
  not?: ModelNeighborhoodConditionInput | null,
};

export type Neighborhood = {
  __typename: "Neighborhood",
  neighborhoodId: string,
  roomId: string,
  restricted?: boolean | null,
  roleIds?: Array< string > | null,
  name?: string | null,
  adminRoleIds?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNeighborhoodInput = {
  neighborhoodId: string,
  roomId: string,
  restricted?: boolean | null,
  roleIds?: Array< string > | null,
  name?: string | null,
  adminRoleIds?: Array< string > | null,
};

export type ModelBookingConditionInput = {
  seatId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  bookerId?: ModelStringInput | null,
  bookerName?: ModelStringInput | null,
  bookerGivenName?: ModelStringInput | null,
  bookerFamilyName?: ModelStringInput | null,
  orgUnitId?: ModelIDInput | null,
  orgUnitAdmin?: ModelStringInput | null,
  bookingFor?: ModelStringInput | null,
  timeBegin?: ModelStringInput | null,
  timeEnd?: ModelStringInput | null,
  and?: Array< ModelBookingConditionInput | null > | null,
  or?: Array< ModelBookingConditionInput | null > | null,
  not?: ModelBookingConditionInput | null,
};

export type Booking = {
  __typename: "Booking",
  bookingId: string,
  date: string,
  seatId: string,
  roomId: string,
  room?: Room | null,
  bookerId: string,
  bookerName: string,
  bookerGivenName?: string | null,
  bookerFamilyName?: string | null,
  orgUnitId: string,
  orgUnitAdmin?: string | null,
  bookingFor?: string | null,
  timeBegin?: string | null,
  timeEnd?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBookingInput = {
  bookingId: string,
  date: string,
  seatId?: string | null,
  roomId?: string | null,
  bookerId?: string | null,
  bookerName?: string | null,
  bookerGivenName?: string | null,
  bookerFamilyName?: string | null,
  orgUnitId?: string | null,
  orgUnitAdmin?: string | null,
  bookingFor?: string | null,
  timeBegin?: string | null,
  timeEnd?: string | null,
};

export type ModelMeetingRoomBookingConditionInput = {
  meetingRoomId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  bookerId?: ModelStringInput | null,
  bookerName?: ModelStringInput | null,
  bookerGivenName?: ModelStringInput | null,
  bookerFamilyName?: ModelStringInput | null,
  orgUnitId?: ModelIDInput | null,
  timeBegin?: ModelStringInput | null,
  timeEnd?: ModelStringInput | null,
  meetingName?: ModelStringInput | null,
  roomCapacity?: ModelIntInput | null,
  participantNumber?: ModelIntInput | null,
  meetingType?: ModelStringInput | null,
  visitors?: ModelStringInput | null,
  meetingRoomSeatConfId?: ModelIDInput | null,
  and?: Array< ModelMeetingRoomBookingConditionInput | null > | null,
  or?: Array< ModelMeetingRoomBookingConditionInput | null > | null,
  not?: ModelMeetingRoomBookingConditionInput | null,
};

export type MeetingRoomBooking = {
  __typename: "MeetingRoomBooking",
  bookingId: string,
  date: string,
  meetingRoomId: string,
  roomId: string,
  bookerId: string,
  bookerName: string,
  bookerGivenName?: string | null,
  bookerFamilyName?: string | null,
  orgUnitId: string,
  timeBegin?: string | null,
  timeEnd?: string | null,
  meetingName?: string | null,
  roomCapacity?: number | null,
  participantNumber?: number | null,
  meetingType?: string | null,
  visitors?: string | null,
  meetingRoomSeatConfId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CreateInventoryInput = {
  inventoryId: string,
  orgUnitAdmin?: string | null,
  orgUnitId: string,
  type: string,
  name: string,
  nameLowerCased: string,
};

export type ModelInventoryConditionInput = {
  orgUnitAdmin?: ModelStringInput | null,
  orgUnitId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameLowerCased?: ModelStringInput | null,
  and?: Array< ModelInventoryConditionInput | null > | null,
  or?: Array< ModelInventoryConditionInput | null > | null,
  not?: ModelInventoryConditionInput | null,
};

export type UpdateInventoryInput = {
  inventoryId: string,
  orgUnitAdmin?: string | null,
  orgUnitId?: string | null,
  type?: string | null,
  name?: string | null,
  nameLowerCased?: string | null,
};

export type ModelMeetingRoomEquipmentConditionInput = {
  buildingId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nameLowerCased?: ModelStringInput | null,
  and?: Array< ModelMeetingRoomEquipmentConditionInput | null > | null,
  or?: Array< ModelMeetingRoomEquipmentConditionInput | null > | null,
  not?: ModelMeetingRoomEquipmentConditionInput | null,
};

export type ModelMeetingRoomSeatConfigConditionInput = {
  buildingId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  meetingRoomId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  imageId?: ModelStringInput | null,
  daysInAdvance?: ModelIntInput | null,
  and?: Array< ModelMeetingRoomSeatConfigConditionInput | null > | null,
  or?: Array< ModelMeetingRoomSeatConfigConditionInput | null > | null,
  not?: ModelMeetingRoomSeatConfigConditionInput | null,
};

export type CreateSeatConfigInput = {
  seatName: string,
  orgUnitAdmin?: string | null,
  roomId: string,
  seatType?: string | null,
  inventory: Array< string >,
  isSeatHeightAdjustable?: boolean | null,
  owner?: string | null,
  isOwnerRegistered?: boolean | null,
  neighborhoodId: string,
  isBookable?: boolean | null,
};

export type ModelSeatConfigConditionInput = {
  orgUnitAdmin?: ModelStringInput | null,
  seatType?: ModelStringInput | null,
  inventory?: ModelIDInput | null,
  isSeatHeightAdjustable?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  isOwnerRegistered?: ModelBooleanInput | null,
  neighborhoodId?: ModelIDInput | null,
  isBookable?: ModelBooleanInput | null,
  and?: Array< ModelSeatConfigConditionInput | null > | null,
  or?: Array< ModelSeatConfigConditionInput | null > | null,
  not?: ModelSeatConfigConditionInput | null,
};

export type UpdateSeatConfigInput = {
  seatName: string,
  orgUnitAdmin?: string | null,
  roomId: string,
  seatType?: string | null,
  inventory?: Array< string > | null,
  isSeatHeightAdjustable?: boolean | null,
  owner?: string | null,
  isOwnerRegistered?: boolean | null,
  neighborhoodId?: string | null,
  isBookable?: boolean | null,
};

export type DeleteSeatConfigInput = {
  roomId: string,
  seatName: string,
};

export type CreateBookingConfigInput = {
  orgUnitId: string,
  orgUnitAdmin?: string | null,
  maxDaysInAdvance?: number | null,
  maxBookableDays?: number | null,
};

export type ModelBookingConfigConditionInput = {
  orgUnitAdmin?: ModelStringInput | null,
  maxDaysInAdvance?: ModelIntInput | null,
  maxBookableDays?: ModelIntInput | null,
  and?: Array< ModelBookingConfigConditionInput | null > | null,
  or?: Array< ModelBookingConfigConditionInput | null > | null,
  not?: ModelBookingConfigConditionInput | null,
};

export type BookingConfig = {
  __typename: "BookingConfig",
  orgUnitId: string,
  orgUnitAdmin?: string | null,
  maxDaysInAdvance?: number | null,
  maxBookableDays?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBookingConfigInput = {
  orgUnitId: string,
  orgUnitAdmin?: string | null,
  maxDaysInAdvance?: number | null,
  maxBookableDays?: number | null,
};

export type DeleteBookingConfigInput = {
  orgUnitId: string,
};

export type CreateMailConfigInput = {
  userId: string,
  userMail: string,
  isReceiveOwnBooking?: boolean | null,
  isReceiveForeignBooking?: boolean | null,
  isReceiveMeetingRoomBooking?: boolean | null,
};

export type ModelMailConfigConditionInput = {
  userMail?: ModelStringInput | null,
  isReceiveOwnBooking?: ModelBooleanInput | null,
  isReceiveForeignBooking?: ModelBooleanInput | null,
  isReceiveMeetingRoomBooking?: ModelBooleanInput | null,
  and?: Array< ModelMailConfigConditionInput | null > | null,
  or?: Array< ModelMailConfigConditionInput | null > | null,
  not?: ModelMailConfigConditionInput | null,
};

export type MailConfig = {
  __typename: "MailConfig",
  userId: string,
  userMail: string,
  isReceiveOwnBooking?: boolean | null,
  isReceiveForeignBooking?: boolean | null,
  isReceiveMeetingRoomBooking?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMailConfigInput = {
  userId: string,
  userMail?: string | null,
  isReceiveOwnBooking?: boolean | null,
  isReceiveForeignBooking?: boolean | null,
  isReceiveMeetingRoomBooking?: boolean | null,
};

export type DeleteMailConfigInput = {
  userId: string,
};

export type CreateDefaultRoomConfigInput = {
  userId: string,
  roomId?: string | null,
  roomName?: string | null,
  neighborhoodId?: string | null,
};

export type ModelDefaultRoomConfigConditionInput = {
  roomId?: ModelIDInput | null,
  roomName?: ModelStringInput | null,
  neighborhoodId?: ModelIDInput | null,
  and?: Array< ModelDefaultRoomConfigConditionInput | null > | null,
  or?: Array< ModelDefaultRoomConfigConditionInput | null > | null,
  not?: ModelDefaultRoomConfigConditionInput | null,
};

export type DefaultRoomConfig = {
  __typename: "DefaultRoomConfig",
  userId: string,
  roomId?: string | null,
  roomName?: string | null,
  neighborhoodId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDefaultRoomConfigInput = {
  userId: string,
  roomId?: string | null,
  roomName?: string | null,
  neighborhoodId?: string | null,
};

export type DeleteDefaultRoomConfigInput = {
  userId: string,
};

export type CreateUserSettingsInput = {
  userId: string,
  consent?: boolean | null,
  userSettingsMailConfigId?: string | null,
  userSettingsDefaultRoomConfigId?: string | null,
};

export type ModelUserSettingsConditionInput = {
  consent?: ModelBooleanInput | null,
  and?: Array< ModelUserSettingsConditionInput | null > | null,
  or?: Array< ModelUserSettingsConditionInput | null > | null,
  not?: ModelUserSettingsConditionInput | null,
  userSettingsMailConfigId?: ModelIDInput | null,
  userSettingsDefaultRoomConfigId?: ModelIDInput | null,
};

export type UserSettings = {
  __typename: "UserSettings",
  userId: string,
  mailConfig?: MailConfig | null,
  defaultRoomConfig?: DefaultRoomConfig | null,
  consent?: boolean | null,
  createdAt: string,
  updatedAt: string,
  userSettingsMailConfigId?: string | null,
  userSettingsDefaultRoomConfigId?: string | null,
};

export type UpdateUserSettingsInput = {
  userId: string,
  consent?: boolean | null,
  userSettingsMailConfigId?: string | null,
  userSettingsDefaultRoomConfigId?: string | null,
};

export type DeleteUserSettingsInput = {
  userId: string,
};

export type CreateInfoTextInput = {
  id?: string | null,
  infoText: string,
  important: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelInfoTextConditionInput = {
  infoText?: ModelStringInput | null,
  important?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInfoTextConditionInput | null > | null,
  or?: Array< ModelInfoTextConditionInput | null > | null,
  not?: ModelInfoTextConditionInput | null,
};

export type InfoText = {
  __typename: "InfoText",
  id: string,
  infoText: string,
  important: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateInfoTextInput = {
  id: string,
  infoText?: string | null,
  important?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteInfoTextInput = {
  id: string,
};

export type CreateHintInput = {
  hintId: string,
  roomId: string,
  buildingId: string,
  text: string,
};

export type ModelHintConditionInput = {
  buildingId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelHintConditionInput | null > | null,
  or?: Array< ModelHintConditionInput | null > | null,
  not?: ModelHintConditionInput | null,
};

export type Hint = {
  __typename: "Hint",
  hintId: string,
  roomId: string,
  buildingId: string,
  text: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateHintInput = {
  hintId: string,
  roomId: string,
  buildingId?: string | null,
  text?: string | null,
};

export type DeleteHintInput = {
  hintId: string,
  roomId: string,
};

export type ModelMeetingRoomConnection = {
  __typename: "ModelMeetingRoomConnection",
  items:  Array<MeetingRoom | null >,
  nextToken?: string | null,
};

export type ModelMeetingRoomBookingConnection = {
  __typename: "ModelMeetingRoomBookingConnection",
  items:  Array<MeetingRoomBooking | null >,
  nextToken?: string | null,
};

export type GetSeatBookingsInput = {
  seatId?: string | null,
  roomId?: string | null,
  date?: string | null,
  orgUnitId?: string | null,
  bookerId?: string | null,
  typeOfQuery?: string | null,
};

export type ModelSeatBookingConnection = {
  __typename: "ModelSeatBookingConnection",
  items:  Array<Booking | null >,
  nextToken?: string | null,
};

export type ModelInventoryConnection = {
  __typename: "ModelInventoryConnection",
  items:  Array<Inventory | null >,
  nextToken?: string | null,
};

export type InventoryByLowerCasedNameInput = {
  nameLowerCased: string,
  type: string,
};

export type ModelNeighborhoodConnection = {
  __typename: "ModelNeighborhoodConnection",
  items:  Array<Neighborhood | null >,
  nextToken?: string | null,
};

export type ModelBuildingFilterInput = {
  buildingId?: ModelIDInput | null,
  buildingName?: ModelStringInput | null,
  roleIds?: ModelStringInput | null,
  buildingNameLowerCased?: ModelStringInput | null,
  seatConfEmail?: ModelStringInput | null,
  and?: Array< ModelBuildingFilterInput | null > | null,
  or?: Array< ModelBuildingFilterInput | null > | null,
  not?: ModelBuildingFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelBuildingConnection = {
  __typename: "ModelBuildingConnection",
  items:  Array<Building | null >,
  nextToken?: string | null,
};

export type ModelRoomFilterInput = {
  roomId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  orgUnitId?: ModelIDInput | null,
  orgUnitAdmin?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameLowerCased?: ModelStringInput | null,
  roomPlanExisting?: ModelBooleanInput | null,
  roomPlanId?: ModelStringInput | null,
  roomPlanScaleFactor?: ModelIntInput | null,
  isActive?: ModelBooleanInput | null,
  isTimeActive?: ModelBooleanInput | null,
  isBookingDisabled?: ModelBooleanInput | null,
  isDefault?: ModelBooleanInput | null,
  isPublic?: ModelBooleanInput | null,
  hasHints?: ModelBooleanInput | null,
  hasNeighborhood?: ModelBooleanInput | null,
  floor?: ModelIntInput | null,
  hasMeetingRooms?: ModelBooleanInput | null,
  and?: Array< ModelRoomFilterInput | null > | null,
  or?: Array< ModelRoomFilterInput | null > | null,
  not?: ModelRoomFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelMeetingRoomFilterInput = {
  meetingRoomId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nameLowerCased?: ModelStringInput | null,
  hintId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  capacity?: ModelIntInput | null,
  roleIds?: ModelStringInput | null,
  neighborhoodId?: ModelIDInput | null,
  equipmentIds?: ModelStringInput | null,
  meetingRoomSeatConfIds?: ModelIDInput | null,
  isBookable?: ModelBooleanInput | null,
  and?: Array< ModelMeetingRoomFilterInput | null > | null,
  or?: Array< ModelMeetingRoomFilterInput | null > | null,
  not?: ModelMeetingRoomFilterInput | null,
};

export type ModelNeighborhoodFilterInput = {
  neighborhoodId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  restricted?: ModelBooleanInput | null,
  roleIds?: ModelStringInput | null,
  name?: ModelStringInput | null,
  adminRoleIds?: ModelStringInput | null,
  and?: Array< ModelNeighborhoodFilterInput | null > | null,
  or?: Array< ModelNeighborhoodFilterInput | null > | null,
  not?: ModelNeighborhoodFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelBookingFilterInput = {
  bookingId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  seatId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  bookerId?: ModelStringInput | null,
  bookerName?: ModelStringInput | null,
  bookerGivenName?: ModelStringInput | null,
  bookerFamilyName?: ModelStringInput | null,
  orgUnitId?: ModelIDInput | null,
  orgUnitAdmin?: ModelStringInput | null,
  bookingFor?: ModelStringInput | null,
  timeBegin?: ModelStringInput | null,
  timeEnd?: ModelStringInput | null,
  and?: Array< ModelBookingFilterInput | null > | null,
  or?: Array< ModelBookingFilterInput | null > | null,
  not?: ModelBookingFilterInput | null,
};

export type ModelBookingConnection = {
  __typename: "ModelBookingConnection",
  items:  Array<Booking | null >,
  nextToken?: string | null,
};

export type ModelMeetingRoomBookingFilterInput = {
  bookingId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  meetingRoomId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  bookerId?: ModelStringInput | null,
  bookerName?: ModelStringInput | null,
  bookerGivenName?: ModelStringInput | null,
  bookerFamilyName?: ModelStringInput | null,
  orgUnitId?: ModelIDInput | null,
  timeBegin?: ModelStringInput | null,
  timeEnd?: ModelStringInput | null,
  meetingName?: ModelStringInput | null,
  roomCapacity?: ModelIntInput | null,
  participantNumber?: ModelIntInput | null,
  meetingType?: ModelStringInput | null,
  visitors?: ModelStringInput | null,
  meetingRoomSeatConfId?: ModelIDInput | null,
  and?: Array< ModelMeetingRoomBookingFilterInput | null > | null,
  or?: Array< ModelMeetingRoomBookingFilterInput | null > | null,
  not?: ModelMeetingRoomBookingFilterInput | null,
};

export type ModelInventoryFilterInput = {
  inventoryId?: ModelIDInput | null,
  orgUnitAdmin?: ModelStringInput | null,
  orgUnitId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameLowerCased?: ModelStringInput | null,
  and?: Array< ModelInventoryFilterInput | null > | null,
  or?: Array< ModelInventoryFilterInput | null > | null,
  not?: ModelInventoryFilterInput | null,
};

export type ModelMeetingRoomEquipmentFilterInput = {
  equipmentId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nameLowerCased?: ModelStringInput | null,
  and?: Array< ModelMeetingRoomEquipmentFilterInput | null > | null,
  or?: Array< ModelMeetingRoomEquipmentFilterInput | null > | null,
  not?: ModelMeetingRoomEquipmentFilterInput | null,
};

export type ModelMeetingRoomEquipmentConnection = {
  __typename: "ModelMeetingRoomEquipmentConnection",
  items:  Array<MeetingRoomEquipment | null >,
  nextToken?: string | null,
};

export type ModelMeetingRoomSeatConfigFilterInput = {
  meetingRoomSeatConfId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  meetingRoomId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  imageId?: ModelStringInput | null,
  daysInAdvance?: ModelIntInput | null,
  and?: Array< ModelMeetingRoomSeatConfigFilterInput | null > | null,
  or?: Array< ModelMeetingRoomSeatConfigFilterInput | null > | null,
  not?: ModelMeetingRoomSeatConfigFilterInput | null,
};

export type ModelMeetingRoomSeatConfigConnection = {
  __typename: "ModelMeetingRoomSeatConfigConnection",
  items:  Array<MeetingRoomSeatConfig | null >,
  nextToken?: string | null,
};

export type ModelSeatConfigFilterInput = {
  seatName?: ModelStringInput | null,
  orgUnitAdmin?: ModelStringInput | null,
  roomId?: ModelIDInput | null,
  seatType?: ModelStringInput | null,
  inventory?: ModelIDInput | null,
  isSeatHeightAdjustable?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  isOwnerRegistered?: ModelBooleanInput | null,
  neighborhoodId?: ModelIDInput | null,
  isBookable?: ModelBooleanInput | null,
  and?: Array< ModelSeatConfigFilterInput | null > | null,
  or?: Array< ModelSeatConfigFilterInput | null > | null,
  not?: ModelSeatConfigFilterInput | null,
};

export type ModelBookingConfigFilterInput = {
  orgUnitId?: ModelIDInput | null,
  orgUnitAdmin?: ModelStringInput | null,
  maxDaysInAdvance?: ModelIntInput | null,
  maxBookableDays?: ModelIntInput | null,
  and?: Array< ModelBookingConfigFilterInput | null > | null,
  or?: Array< ModelBookingConfigFilterInput | null > | null,
  not?: ModelBookingConfigFilterInput | null,
};

export type ModelBookingConfigConnection = {
  __typename: "ModelBookingConfigConnection",
  items:  Array<BookingConfig | null >,
  nextToken?: string | null,
};

export type ModelMailConfigFilterInput = {
  userId?: ModelIDInput | null,
  userMail?: ModelStringInput | null,
  isReceiveOwnBooking?: ModelBooleanInput | null,
  isReceiveForeignBooking?: ModelBooleanInput | null,
  isReceiveMeetingRoomBooking?: ModelBooleanInput | null,
  and?: Array< ModelMailConfigFilterInput | null > | null,
  or?: Array< ModelMailConfigFilterInput | null > | null,
  not?: ModelMailConfigFilterInput | null,
};

export type ModelMailConfigConnection = {
  __typename: "ModelMailConfigConnection",
  items:  Array<MailConfig | null >,
  nextToken?: string | null,
};

export type ModelDefaultRoomConfigFilterInput = {
  userId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  roomName?: ModelStringInput | null,
  neighborhoodId?: ModelIDInput | null,
  and?: Array< ModelDefaultRoomConfigFilterInput | null > | null,
  or?: Array< ModelDefaultRoomConfigFilterInput | null > | null,
  not?: ModelDefaultRoomConfigFilterInput | null,
};

export type ModelDefaultRoomConfigConnection = {
  __typename: "ModelDefaultRoomConfigConnection",
  items:  Array<DefaultRoomConfig | null >,
  nextToken?: string | null,
};

export type ModelUserSettingsFilterInput = {
  userId?: ModelIDInput | null,
  consent?: ModelBooleanInput | null,
  and?: Array< ModelUserSettingsFilterInput | null > | null,
  or?: Array< ModelUserSettingsFilterInput | null > | null,
  not?: ModelUserSettingsFilterInput | null,
  userSettingsMailConfigId?: ModelIDInput | null,
  userSettingsDefaultRoomConfigId?: ModelIDInput | null,
};

export type ModelUserSettingsConnection = {
  __typename: "ModelUserSettingsConnection",
  items:  Array<UserSettings | null >,
  nextToken?: string | null,
};

export type ModelInfoTextFilterInput = {
  id?: ModelIDInput | null,
  infoText?: ModelStringInput | null,
  important?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInfoTextFilterInput | null > | null,
  or?: Array< ModelInfoTextFilterInput | null > | null,
  not?: ModelInfoTextFilterInput | null,
};

export type ModelInfoTextConnection = {
  __typename: "ModelInfoTextConnection",
  items:  Array<InfoText | null >,
  nextToken?: string | null,
};

export type ModelHintFilterInput = {
  hintId?: ModelIDInput | null,
  roomId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelHintFilterInput | null > | null,
  or?: Array< ModelHintFilterInput | null > | null,
  not?: ModelHintFilterInput | null,
};

export type ModelHintConnection = {
  __typename: "ModelHintConnection",
  items:  Array<Hint | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionBuildingFilterInput = {
  buildingId?: ModelSubscriptionIDInput | null,
  buildingName?: ModelSubscriptionStringInput | null,
  roleIds?: ModelSubscriptionStringInput | null,
  buildingNameLowerCased?: ModelSubscriptionStringInput | null,
  seatConfEmail?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBuildingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBuildingFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionInventoryFilterInput = {
  inventoryId?: ModelSubscriptionIDInput | null,
  orgUnitId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  nameLowerCased?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInventoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionInventoryFilterInput | null > | null,
};

export type ModelSubscriptionMeetingRoomEquipmentFilterInput = {
  equipmentId?: ModelSubscriptionIDInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  nameLowerCased?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMeetingRoomEquipmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionMeetingRoomEquipmentFilterInput | null > | null,
};

export type ModelSubscriptionMeetingRoomSeatConfigFilterInput = {
  meetingRoomSeatConfId?: ModelSubscriptionIDInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  roomId?: ModelSubscriptionIDInput | null,
  meetingRoomId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  imageId?: ModelSubscriptionStringInput | null,
  daysInAdvance?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionMeetingRoomSeatConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionMeetingRoomSeatConfigFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionSeatConfigFilterInput = {
  seatName?: ModelSubscriptionStringInput | null,
  roomId?: ModelSubscriptionIDInput | null,
  seatType?: ModelSubscriptionStringInput | null,
  inventory?: ModelSubscriptionIDInput | null,
  isSeatHeightAdjustable?: ModelSubscriptionBooleanInput | null,
  owner?: ModelSubscriptionStringInput | null,
  isOwnerRegistered?: ModelSubscriptionBooleanInput | null,
  neighborhoodId?: ModelSubscriptionIDInput | null,
  isBookable?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionSeatConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionSeatConfigFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionBookingConfigFilterInput = {
  orgUnitId?: ModelSubscriptionIDInput | null,
  maxDaysInAdvance?: ModelSubscriptionIntInput | null,
  maxBookableDays?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionBookingConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingConfigFilterInput | null > | null,
};

export type ModelSubscriptionMailConfigFilterInput = {
  userMail?: ModelSubscriptionStringInput | null,
  isReceiveOwnBooking?: ModelSubscriptionBooleanInput | null,
  isReceiveForeignBooking?: ModelSubscriptionBooleanInput | null,
  isReceiveMeetingRoomBooking?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionMailConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionMailConfigFilterInput | null > | null,
};

export type ModelSubscriptionDefaultRoomConfigFilterInput = {
  roomId?: ModelSubscriptionIDInput | null,
  roomName?: ModelSubscriptionStringInput | null,
  neighborhoodId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionDefaultRoomConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionDefaultRoomConfigFilterInput | null > | null,
};

export type ModelSubscriptionUserSettingsFilterInput = {
  consent?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionUserSettingsFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserSettingsFilterInput | null > | null,
};

export type ModelSubscriptionInfoTextFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  infoText?: ModelSubscriptionStringInput | null,
  important?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInfoTextFilterInput | null > | null,
  or?: Array< ModelSubscriptionInfoTextFilterInput | null > | null,
};

export type PublishRoomAccessChangeMutationVariables = {
  roomId: string,
};

export type PublishRoomAccessChangeMutation = {
  publishRoomAccessChange?: string | null,
};

export type RoomPlanSecureUploadActionsMutationVariables = {
  input?: RoomPlanUploadActionsInput | null,
};

export type RoomPlanSecureUploadActionsMutation = {
  roomPlanSecureUploadActions?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type CreateBookingsMutationVariables = {
  input?: Array< CreateBookingInput | null > | null,
};

export type CreateBookingsMutation = {
  createBookings?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type UpdateSecureSeatBookingMutationVariables = {
  input?: UpdateSeatBookingInput | null,
};

export type UpdateSecureSeatBookingMutation = {
  updateSecureSeatBooking?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type DeleteSecureSeatBookingMutationVariables = {
  input?: DeleteBookingInput | null,
};

export type DeleteSecureSeatBookingMutation = {
  deleteSecureSeatBooking?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type DeleteSecureSeatBookingsByUserIdMutationVariables = {
  input?: DeleteBookingsByUserInput | null,
};

export type DeleteSecureSeatBookingsByUserIdMutation = {
  deleteSecureSeatBookingsByUserId?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type CreateSecureBuildingMutationVariables = {
  input?: CreateBuildingInput | null,
};

export type CreateSecureBuildingMutation = {
  createSecureBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSecureBuildingMutationVariables = {
  input?: UpdateBuildingInput | null,
};

export type UpdateSecureBuildingMutation = {
  updateSecureBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSecureBuildingMutationVariables = {
  input?: DeleteBuildingInput | null,
};

export type DeleteSecureBuildingMutation = {
  deleteSecureBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingRoomsMutationVariables = {
  input?: Array< CreateMeetingRoomInput | null > | null,
};

export type CreateMeetingRoomsMutation = {
  createMeetingRooms?:  Array< {
    __typename: "MeetingRoom",
    meetingRoomId: string,
    roomId: string,
    name: string,
    nameLowerCased: string,
    hintId: string,
    type: string,
    capacity?: number | null,
    roleIds?: Array< string > | null,
    neighborhoodId: string,
    equipmentIds?: Array< string > | null,
    meetingRoomSeatConfIds?: Array< string > | null,
    isBookable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type UpdateSecureMeetingRoomMutationVariables = {
  input?: UpdateMeetingRoomInput | null,
};

export type UpdateSecureMeetingRoomMutation = {
  updateSecureMeetingRoom?:  {
    __typename: "MeetingRoom",
    meetingRoomId: string,
    roomId: string,
    name: string,
    nameLowerCased: string,
    hintId: string,
    type: string,
    capacity?: number | null,
    roleIds?: Array< string > | null,
    neighborhoodId: string,
    equipmentIds?: Array< string > | null,
    meetingRoomSeatConfIds?: Array< string > | null,
    isBookable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSecureMeetingRoomMutationVariables = {
  input?: DeleteMeetingRoomInput | null,
};

export type DeleteSecureMeetingRoomMutation = {
  deleteSecureMeetingRoom?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type DeleteAllMeetingRoomBookingsMutationVariables = {
  meetingRoomId: string,
};

export type DeleteAllMeetingRoomBookingsMutation = {
  deleteAllMeetingRoomBookings?: string | null,
};

export type CreateSecureRoomMutationVariables = {
  input?: CreateRoomInput | null,
};

export type CreateSecureRoomMutation = {
  createSecureRoom?:  {
    __typename: "Room",
    roomId: string,
    buildingId?: string | null,
    orgUnitId?: string | null,
    orgUnitAdmin?: string | null,
    name: string,
    nameLowerCased: string,
    roomPlanExisting?: boolean | null,
    roomPlanId?: string | null,
    roomPlanScaleFactor?: number | null,
    seats?:  {
      __typename: "ModelSeatConfigConnection",
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    isTimeActive?: boolean | null,
    isBookingDisabled?: boolean | null,
    isDefault?: boolean | null,
    isPublic?: boolean | null,
    hasHints?: boolean | null,
    hasNeighborhood?: boolean | null,
    floor?: number | null,
    hasMeetingRooms?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSecureRoomMutationVariables = {
  input?: UpdateRoomInput | null,
  updateType?: string | null,
};

export type UpdateSecureRoomMutation = {
  updateSecureRoom?:  {
    __typename: "Room",
    roomId: string,
    buildingId?: string | null,
    orgUnitId?: string | null,
    orgUnitAdmin?: string | null,
    name: string,
    nameLowerCased: string,
    roomPlanExisting?: boolean | null,
    roomPlanId?: string | null,
    roomPlanScaleFactor?: number | null,
    seats?:  {
      __typename: "ModelSeatConfigConnection",
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    isTimeActive?: boolean | null,
    isBookingDisabled?: boolean | null,
    isDefault?: boolean | null,
    isPublic?: boolean | null,
    hasHints?: boolean | null,
    hasNeighborhood?: boolean | null,
    floor?: number | null,
    hasMeetingRooms?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSecureRoomMutationVariables = {
  input?: DeleteRoomInput | null,
};

export type DeleteSecureRoomMutation = {
  deleteSecureRoom?:  {
    __typename: "Room",
    roomId: string,
    buildingId?: string | null,
    orgUnitId?: string | null,
    orgUnitAdmin?: string | null,
    name: string,
    nameLowerCased: string,
    roomPlanExisting?: boolean | null,
    roomPlanId?: string | null,
    roomPlanScaleFactor?: number | null,
    seats?:  {
      __typename: "ModelSeatConfigConnection",
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    isTimeActive?: boolean | null,
    isBookingDisabled?: boolean | null,
    isDefault?: boolean | null,
    isPublic?: boolean | null,
    hasHints?: boolean | null,
    hasNeighborhood?: boolean | null,
    floor?: number | null,
    hasMeetingRooms?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSecureSeatConfigMutationVariables = {
  input?: CreateSeatConfig | null,
};

export type CreateSecureSeatConfigMutation = {
  createSecureSeatConfig?:  {
    __typename: "SeatConfig",
    seatName: string,
    orgUnitAdmin?: string | null,
    roomId: string,
    seatType?: string | null,
    inventory: Array< string >,
    isSeatHeightAdjustable?: boolean | null,
    owner?: string | null,
    isOwnerRegistered?: boolean | null,
    neighborhoodId: string,
    isBookable?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSecureSeatConfigMutationVariables = {
  input?: DeleteSeatConfig | null,
};

export type DeleteSecureSeatConfigMutation = {
  deleteSecureSeatConfig?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type CreateMeetingRoomBookingsMutationVariables = {
  input?: Array< CreateMeetingRoomBookingInput | null > | null,
};

export type CreateMeetingRoomBookingsMutation = {
  createMeetingRoomBookings?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type UpdateSecureMeetingRoomBookingMutationVariables = {
  input?: UpdateMeetingRoomBookingInput | null,
};

export type UpdateSecureMeetingRoomBookingMutation = {
  updateSecureMeetingRoomBooking?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type DeleteSecureMeetingRoomBookingMutationVariables = {
  input?: DeleteMeetingRoomBookingInput | null,
};

export type DeleteSecureMeetingRoomBookingMutation = {
  deleteSecureMeetingRoomBooking?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type UpdateSeatConfigsMutationVariables = {
  input?: UpdateSeatConfigsInput | null,
};

export type UpdateSeatConfigsMutation = {
  updateSeatConfigs?:  {
    __typename: "UpdateSeatConfigsOutput",
    message?: UpdateSeatConfigsError | null,
    cause?: string | null,
  } | null,
};

export type DeleteBookingsBySeatIdsMutationVariables = {
  input?: DeleteBookingsBySeatIdsInput | null,
};

export type DeleteBookingsBySeatIdsMutation = {
  deleteBookingsBySeatIds?: string | null,
};

export type UpdateRestrictedNeighborhoodsMutationVariables = {
  input?: UpdateRestrictedNeighborhoodsInput | null,
};

export type UpdateRestrictedNeighborhoodsMutation = {
  updateRestrictedNeighborhoods?:  {
    __typename: "UpdateRestrictedNeighborhoodsOutput",
    statusCode?: number | null,
    message?: string | null,
  } | null,
};

export type CreateSecureInventoryMutationVariables = {
  input?: InventoryInput | null,
};

export type CreateSecureInventoryMutation = {
  createSecureInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSecureInventoryMutationVariables = {
  input?: DeleteInventoryInput | null,
};

export type DeleteSecureInventoryMutation = {
  deleteSecureInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSecureInventoryMutationVariables = {
  input?: InventoryInput | null,
};

export type UpdateSecureInventoryMutation = {
  updateSecureInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSecureNeighborhoodsMutationVariables = {
  input?: CreateNeighborhoodsInput | null,
};

export type CreateSecureNeighborhoodsMutation = {
  createSecureNeighborhoods?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type DeleteSecureNeighborhoodsMutationVariables = {
  input?: DeleteNeighborhoodsInput | null,
};

export type DeleteSecureNeighborhoodsMutation = {
  deleteSecureNeighborhoods?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type CreateSecureMeetingRoomEquipmentMutationVariables = {
  input?: CreateMeetingRoomEquipmentInput | null,
};

export type CreateSecureMeetingRoomEquipmentMutation = {
  createSecureMeetingRoomEquipment?:  {
    __typename: "MeetingRoomEquipment",
    equipmentId: string,
    buildingId: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSecureMeetingRoomEquipmentMutationVariables = {
  input?: UpdateMeetingRoomEquipmentInput | null,
};

export type UpdateSecureMeetingRoomEquipmentMutation = {
  updateSecureMeetingRoomEquipment?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type DeleteSecureMeetingRoomEquipmentMutationVariables = {
  input?: DeleteMeetingRoomEquipmentInput | null,
};

export type DeleteSecureMeetingRoomEquipmentMutation = {
  deleteSecureMeetingRoomEquipment?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type CreateSecureMeetingRoomSeatConfigMutationVariables = {
  input?: CreateMeetingRoomSeatConfigInput | null,
};

export type CreateSecureMeetingRoomSeatConfigMutation = {
  createSecureMeetingRoomSeatConfig?:  {
    __typename: "MeetingRoomSeatConfig",
    meetingRoomSeatConfId: string,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name: string,
    imageId?: string | null,
    daysInAdvance: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSecureMeetingRoomSeatConfigMutationVariables = {
  input?: UpdateMeetingRoomSeatConfigInput | null,
};

export type UpdateSecureMeetingRoomSeatConfigMutation = {
  updateSecureMeetingRoomSeatConfig?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type DeleteSecureMeetingRoomSeatConfigMutationVariables = {
  input?: DeleteMeetingRoomSeatConfigInput | null,
};

export type DeleteSecureMeetingRoomSeatConfigMutation = {
  deleteSecureMeetingRoomSeatConfig?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type CreateBuildingMutationVariables = {
  input: CreateBuildingInput,
  condition?: ModelBuildingConditionInput | null,
};

export type CreateBuildingMutation = {
  createBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBuildingMutationVariables = {
  input: UpdateBuildingInput,
  condition?: ModelBuildingConditionInput | null,
};

export type UpdateBuildingMutation = {
  updateBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBuildingMutationVariables = {
  input: DeleteBuildingInput,
  condition?: ModelBuildingConditionInput | null,
};

export type DeleteBuildingMutation = {
  deleteBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRoomMutationVariables = {
  input: CreateRoomInput,
  condition?: ModelRoomConditionInput | null,
};

export type CreateRoomMutation = {
  createRoom?:  {
    __typename: "Room",
    roomId: string,
    buildingId?: string | null,
    orgUnitId?: string | null,
    orgUnitAdmin?: string | null,
    name: string,
    nameLowerCased: string,
    roomPlanExisting?: boolean | null,
    roomPlanId?: string | null,
    roomPlanScaleFactor?: number | null,
    seats?:  {
      __typename: "ModelSeatConfigConnection",
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    isTimeActive?: boolean | null,
    isBookingDisabled?: boolean | null,
    isDefault?: boolean | null,
    isPublic?: boolean | null,
    hasHints?: boolean | null,
    hasNeighborhood?: boolean | null,
    floor?: number | null,
    hasMeetingRooms?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRoomMutationVariables = {
  input: UpdateRoomInput,
  condition?: ModelRoomConditionInput | null,
};

export type UpdateRoomMutation = {
  updateRoom?:  {
    __typename: "Room",
    roomId: string,
    buildingId?: string | null,
    orgUnitId?: string | null,
    orgUnitAdmin?: string | null,
    name: string,
    nameLowerCased: string,
    roomPlanExisting?: boolean | null,
    roomPlanId?: string | null,
    roomPlanScaleFactor?: number | null,
    seats?:  {
      __typename: "ModelSeatConfigConnection",
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    isTimeActive?: boolean | null,
    isBookingDisabled?: boolean | null,
    isDefault?: boolean | null,
    isPublic?: boolean | null,
    hasHints?: boolean | null,
    hasNeighborhood?: boolean | null,
    floor?: number | null,
    hasMeetingRooms?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRoomMutationVariables = {
  input: DeleteRoomInput,
  condition?: ModelRoomConditionInput | null,
};

export type DeleteRoomMutation = {
  deleteRoom?:  {
    __typename: "Room",
    roomId: string,
    buildingId?: string | null,
    orgUnitId?: string | null,
    orgUnitAdmin?: string | null,
    name: string,
    nameLowerCased: string,
    roomPlanExisting?: boolean | null,
    roomPlanId?: string | null,
    roomPlanScaleFactor?: number | null,
    seats?:  {
      __typename: "ModelSeatConfigConnection",
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    isTimeActive?: boolean | null,
    isBookingDisabled?: boolean | null,
    isDefault?: boolean | null,
    isPublic?: boolean | null,
    hasHints?: boolean | null,
    hasNeighborhood?: boolean | null,
    floor?: number | null,
    hasMeetingRooms?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingRoomMutationVariables = {
  input: CreateMeetingRoomInput,
  condition?: ModelMeetingRoomConditionInput | null,
};

export type CreateMeetingRoomMutation = {
  createMeetingRoom?:  {
    __typename: "MeetingRoom",
    meetingRoomId: string,
    roomId: string,
    name: string,
    nameLowerCased: string,
    hintId: string,
    type: string,
    capacity?: number | null,
    roleIds?: Array< string > | null,
    neighborhoodId: string,
    equipmentIds?: Array< string > | null,
    meetingRoomSeatConfIds?: Array< string > | null,
    isBookable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMeetingRoomMutationVariables = {
  input: UpdateMeetingRoomInput,
  condition?: ModelMeetingRoomConditionInput | null,
};

export type UpdateMeetingRoomMutation = {
  updateMeetingRoom?:  {
    __typename: "MeetingRoom",
    meetingRoomId: string,
    roomId: string,
    name: string,
    nameLowerCased: string,
    hintId: string,
    type: string,
    capacity?: number | null,
    roleIds?: Array< string > | null,
    neighborhoodId: string,
    equipmentIds?: Array< string > | null,
    meetingRoomSeatConfIds?: Array< string > | null,
    isBookable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMeetingRoomMutationVariables = {
  input: DeleteMeetingRoomInput,
  condition?: ModelMeetingRoomConditionInput | null,
};

export type DeleteMeetingRoomMutation = {
  deleteMeetingRoom?:  {
    __typename: "MeetingRoom",
    meetingRoomId: string,
    roomId: string,
    name: string,
    nameLowerCased: string,
    hintId: string,
    type: string,
    capacity?: number | null,
    roleIds?: Array< string > | null,
    neighborhoodId: string,
    equipmentIds?: Array< string > | null,
    meetingRoomSeatConfIds?: Array< string > | null,
    isBookable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNeighborhoodMutationVariables = {
  input: CreateNeighborhoodInput,
  condition?: ModelNeighborhoodConditionInput | null,
};

export type CreateNeighborhoodMutation = {
  createNeighborhood?:  {
    __typename: "Neighborhood",
    neighborhoodId: string,
    roomId: string,
    restricted?: boolean | null,
    roleIds?: Array< string > | null,
    name?: string | null,
    adminRoleIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNeighborhoodMutationVariables = {
  input: UpdateNeighborhoodInput,
  condition?: ModelNeighborhoodConditionInput | null,
};

export type UpdateNeighborhoodMutation = {
  updateNeighborhood?:  {
    __typename: "Neighborhood",
    neighborhoodId: string,
    roomId: string,
    restricted?: boolean | null,
    roleIds?: Array< string > | null,
    name?: string | null,
    adminRoleIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNeighborhoodMutationVariables = {
  input: DeleteNeighborhoodInput,
  condition?: ModelNeighborhoodConditionInput | null,
};

export type DeleteNeighborhoodMutation = {
  deleteNeighborhood?:  {
    __typename: "Neighborhood",
    neighborhoodId: string,
    roomId: string,
    restricted?: boolean | null,
    roleIds?: Array< string > | null,
    name?: string | null,
    adminRoleIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBookingMutationVariables = {
  input: CreateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type CreateBookingMutation = {
  createBooking?:  {
    __typename: "Booking",
    bookingId: string,
    date: string,
    seatId: string,
    roomId: string,
    room?:  {
      __typename: "Room",
      roomId: string,
      buildingId?: string | null,
      orgUnitId?: string | null,
      orgUnitAdmin?: string | null,
      name: string,
      nameLowerCased: string,
      roomPlanExisting?: boolean | null,
      roomPlanId?: string | null,
      roomPlanScaleFactor?: number | null,
      isActive?: boolean | null,
      isTimeActive?: boolean | null,
      isBookingDisabled?: boolean | null,
      isDefault?: boolean | null,
      isPublic?: boolean | null,
      hasHints?: boolean | null,
      hasNeighborhood?: boolean | null,
      floor?: number | null,
      hasMeetingRooms?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookerId: string,
    bookerName: string,
    bookerGivenName?: string | null,
    bookerFamilyName?: string | null,
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    bookingFor?: string | null,
    timeBegin?: string | null,
    timeEnd?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBookingMutationVariables = {
  input: UpdateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type UpdateBookingMutation = {
  updateBooking?:  {
    __typename: "Booking",
    bookingId: string,
    date: string,
    seatId: string,
    roomId: string,
    room?:  {
      __typename: "Room",
      roomId: string,
      buildingId?: string | null,
      orgUnitId?: string | null,
      orgUnitAdmin?: string | null,
      name: string,
      nameLowerCased: string,
      roomPlanExisting?: boolean | null,
      roomPlanId?: string | null,
      roomPlanScaleFactor?: number | null,
      isActive?: boolean | null,
      isTimeActive?: boolean | null,
      isBookingDisabled?: boolean | null,
      isDefault?: boolean | null,
      isPublic?: boolean | null,
      hasHints?: boolean | null,
      hasNeighborhood?: boolean | null,
      floor?: number | null,
      hasMeetingRooms?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookerId: string,
    bookerName: string,
    bookerGivenName?: string | null,
    bookerFamilyName?: string | null,
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    bookingFor?: string | null,
    timeBegin?: string | null,
    timeEnd?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBookingMutationVariables = {
  input: DeleteBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type DeleteBookingMutation = {
  deleteBooking?:  {
    __typename: "Booking",
    bookingId: string,
    date: string,
    seatId: string,
    roomId: string,
    room?:  {
      __typename: "Room",
      roomId: string,
      buildingId?: string | null,
      orgUnitId?: string | null,
      orgUnitAdmin?: string | null,
      name: string,
      nameLowerCased: string,
      roomPlanExisting?: boolean | null,
      roomPlanId?: string | null,
      roomPlanScaleFactor?: number | null,
      isActive?: boolean | null,
      isTimeActive?: boolean | null,
      isBookingDisabled?: boolean | null,
      isDefault?: boolean | null,
      isPublic?: boolean | null,
      hasHints?: boolean | null,
      hasNeighborhood?: boolean | null,
      floor?: number | null,
      hasMeetingRooms?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookerId: string,
    bookerName: string,
    bookerGivenName?: string | null,
    bookerFamilyName?: string | null,
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    bookingFor?: string | null,
    timeBegin?: string | null,
    timeEnd?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingRoomBookingMutationVariables = {
  input: CreateMeetingRoomBookingInput,
  condition?: ModelMeetingRoomBookingConditionInput | null,
};

export type CreateMeetingRoomBookingMutation = {
  createMeetingRoomBooking?:  {
    __typename: "MeetingRoomBooking",
    bookingId: string,
    date: string,
    meetingRoomId: string,
    roomId: string,
    bookerId: string,
    bookerName: string,
    bookerGivenName?: string | null,
    bookerFamilyName?: string | null,
    orgUnitId: string,
    timeBegin?: string | null,
    timeEnd?: string | null,
    meetingName?: string | null,
    roomCapacity?: number | null,
    participantNumber?: number | null,
    meetingType?: string | null,
    visitors?: string | null,
    meetingRoomSeatConfId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMeetingRoomBookingMutationVariables = {
  input: UpdateMeetingRoomBookingInput,
  condition?: ModelMeetingRoomBookingConditionInput | null,
};

export type UpdateMeetingRoomBookingMutation = {
  updateMeetingRoomBooking?:  {
    __typename: "MeetingRoomBooking",
    bookingId: string,
    date: string,
    meetingRoomId: string,
    roomId: string,
    bookerId: string,
    bookerName: string,
    bookerGivenName?: string | null,
    bookerFamilyName?: string | null,
    orgUnitId: string,
    timeBegin?: string | null,
    timeEnd?: string | null,
    meetingName?: string | null,
    roomCapacity?: number | null,
    participantNumber?: number | null,
    meetingType?: string | null,
    visitors?: string | null,
    meetingRoomSeatConfId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMeetingRoomBookingMutationVariables = {
  input: DeleteMeetingRoomBookingInput,
  condition?: ModelMeetingRoomBookingConditionInput | null,
};

export type DeleteMeetingRoomBookingMutation = {
  deleteMeetingRoomBooking?:  {
    __typename: "MeetingRoomBooking",
    bookingId: string,
    date: string,
    meetingRoomId: string,
    roomId: string,
    bookerId: string,
    bookerName: string,
    bookerGivenName?: string | null,
    bookerFamilyName?: string | null,
    orgUnitId: string,
    timeBegin?: string | null,
    timeEnd?: string | null,
    meetingName?: string | null,
    roomCapacity?: number | null,
    participantNumber?: number | null,
    meetingType?: string | null,
    visitors?: string | null,
    meetingRoomSeatConfId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInventoryMutationVariables = {
  input: CreateInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type CreateInventoryMutation = {
  createInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInventoryMutationVariables = {
  input: UpdateInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type UpdateInventoryMutation = {
  updateInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInventoryMutationVariables = {
  input: DeleteInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type DeleteInventoryMutation = {
  deleteInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingRoomEquipmentMutationVariables = {
  input: CreateMeetingRoomEquipmentInput,
  condition?: ModelMeetingRoomEquipmentConditionInput | null,
};

export type CreateMeetingRoomEquipmentMutation = {
  createMeetingRoomEquipment?:  {
    __typename: "MeetingRoomEquipment",
    equipmentId: string,
    buildingId: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMeetingRoomEquipmentMutationVariables = {
  input: UpdateMeetingRoomEquipmentInput,
  condition?: ModelMeetingRoomEquipmentConditionInput | null,
};

export type UpdateMeetingRoomEquipmentMutation = {
  updateMeetingRoomEquipment?:  {
    __typename: "MeetingRoomEquipment",
    equipmentId: string,
    buildingId: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMeetingRoomEquipmentMutationVariables = {
  input: DeleteMeetingRoomEquipmentInput,
  condition?: ModelMeetingRoomEquipmentConditionInput | null,
};

export type DeleteMeetingRoomEquipmentMutation = {
  deleteMeetingRoomEquipment?:  {
    __typename: "MeetingRoomEquipment",
    equipmentId: string,
    buildingId: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingRoomSeatConfigMutationVariables = {
  input: CreateMeetingRoomSeatConfigInput,
  condition?: ModelMeetingRoomSeatConfigConditionInput | null,
};

export type CreateMeetingRoomSeatConfigMutation = {
  createMeetingRoomSeatConfig?:  {
    __typename: "MeetingRoomSeatConfig",
    meetingRoomSeatConfId: string,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name: string,
    imageId?: string | null,
    daysInAdvance: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMeetingRoomSeatConfigMutationVariables = {
  input: UpdateMeetingRoomSeatConfigInput,
  condition?: ModelMeetingRoomSeatConfigConditionInput | null,
};

export type UpdateMeetingRoomSeatConfigMutation = {
  updateMeetingRoomSeatConfig?:  {
    __typename: "MeetingRoomSeatConfig",
    meetingRoomSeatConfId: string,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name: string,
    imageId?: string | null,
    daysInAdvance: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMeetingRoomSeatConfigMutationVariables = {
  input: DeleteMeetingRoomSeatConfigInput,
  condition?: ModelMeetingRoomSeatConfigConditionInput | null,
};

export type DeleteMeetingRoomSeatConfigMutation = {
  deleteMeetingRoomSeatConfig?:  {
    __typename: "MeetingRoomSeatConfig",
    meetingRoomSeatConfId: string,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name: string,
    imageId?: string | null,
    daysInAdvance: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSeatConfigMutationVariables = {
  input: CreateSeatConfigInput,
  condition?: ModelSeatConfigConditionInput | null,
};

export type CreateSeatConfigMutation = {
  createSeatConfig?:  {
    __typename: "SeatConfig",
    seatName: string,
    orgUnitAdmin?: string | null,
    roomId: string,
    seatType?: string | null,
    inventory: Array< string >,
    isSeatHeightAdjustable?: boolean | null,
    owner?: string | null,
    isOwnerRegistered?: boolean | null,
    neighborhoodId: string,
    isBookable?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSeatConfigMutationVariables = {
  input: UpdateSeatConfigInput,
  condition?: ModelSeatConfigConditionInput | null,
};

export type UpdateSeatConfigMutation = {
  updateSeatConfig?:  {
    __typename: "SeatConfig",
    seatName: string,
    orgUnitAdmin?: string | null,
    roomId: string,
    seatType?: string | null,
    inventory: Array< string >,
    isSeatHeightAdjustable?: boolean | null,
    owner?: string | null,
    isOwnerRegistered?: boolean | null,
    neighborhoodId: string,
    isBookable?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSeatConfigMutationVariables = {
  input: DeleteSeatConfigInput,
  condition?: ModelSeatConfigConditionInput | null,
};

export type DeleteSeatConfigMutation = {
  deleteSeatConfig?:  {
    __typename: "SeatConfig",
    seatName: string,
    orgUnitAdmin?: string | null,
    roomId: string,
    seatType?: string | null,
    inventory: Array< string >,
    isSeatHeightAdjustable?: boolean | null,
    owner?: string | null,
    isOwnerRegistered?: boolean | null,
    neighborhoodId: string,
    isBookable?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBookingConfigMutationVariables = {
  input: CreateBookingConfigInput,
  condition?: ModelBookingConfigConditionInput | null,
};

export type CreateBookingConfigMutation = {
  createBookingConfig?:  {
    __typename: "BookingConfig",
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    maxDaysInAdvance?: number | null,
    maxBookableDays?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBookingConfigMutationVariables = {
  input: UpdateBookingConfigInput,
  condition?: ModelBookingConfigConditionInput | null,
};

export type UpdateBookingConfigMutation = {
  updateBookingConfig?:  {
    __typename: "BookingConfig",
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    maxDaysInAdvance?: number | null,
    maxBookableDays?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBookingConfigMutationVariables = {
  input: DeleteBookingConfigInput,
  condition?: ModelBookingConfigConditionInput | null,
};

export type DeleteBookingConfigMutation = {
  deleteBookingConfig?:  {
    __typename: "BookingConfig",
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    maxDaysInAdvance?: number | null,
    maxBookableDays?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMailConfigMutationVariables = {
  input: CreateMailConfigInput,
  condition?: ModelMailConfigConditionInput | null,
};

export type CreateMailConfigMutation = {
  createMailConfig?:  {
    __typename: "MailConfig",
    userId: string,
    userMail: string,
    isReceiveOwnBooking?: boolean | null,
    isReceiveForeignBooking?: boolean | null,
    isReceiveMeetingRoomBooking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMailConfigMutationVariables = {
  input: UpdateMailConfigInput,
  condition?: ModelMailConfigConditionInput | null,
};

export type UpdateMailConfigMutation = {
  updateMailConfig?:  {
    __typename: "MailConfig",
    userId: string,
    userMail: string,
    isReceiveOwnBooking?: boolean | null,
    isReceiveForeignBooking?: boolean | null,
    isReceiveMeetingRoomBooking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMailConfigMutationVariables = {
  input: DeleteMailConfigInput,
  condition?: ModelMailConfigConditionInput | null,
};

export type DeleteMailConfigMutation = {
  deleteMailConfig?:  {
    __typename: "MailConfig",
    userId: string,
    userMail: string,
    isReceiveOwnBooking?: boolean | null,
    isReceiveForeignBooking?: boolean | null,
    isReceiveMeetingRoomBooking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDefaultRoomConfigMutationVariables = {
  input: CreateDefaultRoomConfigInput,
  condition?: ModelDefaultRoomConfigConditionInput | null,
};

export type CreateDefaultRoomConfigMutation = {
  createDefaultRoomConfig?:  {
    __typename: "DefaultRoomConfig",
    userId: string,
    roomId?: string | null,
    roomName?: string | null,
    neighborhoodId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDefaultRoomConfigMutationVariables = {
  input: UpdateDefaultRoomConfigInput,
  condition?: ModelDefaultRoomConfigConditionInput | null,
};

export type UpdateDefaultRoomConfigMutation = {
  updateDefaultRoomConfig?:  {
    __typename: "DefaultRoomConfig",
    userId: string,
    roomId?: string | null,
    roomName?: string | null,
    neighborhoodId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDefaultRoomConfigMutationVariables = {
  input: DeleteDefaultRoomConfigInput,
  condition?: ModelDefaultRoomConfigConditionInput | null,
};

export type DeleteDefaultRoomConfigMutation = {
  deleteDefaultRoomConfig?:  {
    __typename: "DefaultRoomConfig",
    userId: string,
    roomId?: string | null,
    roomName?: string | null,
    neighborhoodId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserSettingsMutationVariables = {
  input: CreateUserSettingsInput,
  condition?: ModelUserSettingsConditionInput | null,
};

export type CreateUserSettingsMutation = {
  createUserSettings?:  {
    __typename: "UserSettings",
    userId: string,
    mailConfig?:  {
      __typename: "MailConfig",
      userId: string,
      userMail: string,
      isReceiveOwnBooking?: boolean | null,
      isReceiveForeignBooking?: boolean | null,
      isReceiveMeetingRoomBooking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    defaultRoomConfig?:  {
      __typename: "DefaultRoomConfig",
      userId: string,
      roomId?: string | null,
      roomName?: string | null,
      neighborhoodId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    consent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    userSettingsMailConfigId?: string | null,
    userSettingsDefaultRoomConfigId?: string | null,
  } | null,
};

export type UpdateUserSettingsMutationVariables = {
  input: UpdateUserSettingsInput,
  condition?: ModelUserSettingsConditionInput | null,
};

export type UpdateUserSettingsMutation = {
  updateUserSettings?:  {
    __typename: "UserSettings",
    userId: string,
    mailConfig?:  {
      __typename: "MailConfig",
      userId: string,
      userMail: string,
      isReceiveOwnBooking?: boolean | null,
      isReceiveForeignBooking?: boolean | null,
      isReceiveMeetingRoomBooking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    defaultRoomConfig?:  {
      __typename: "DefaultRoomConfig",
      userId: string,
      roomId?: string | null,
      roomName?: string | null,
      neighborhoodId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    consent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    userSettingsMailConfigId?: string | null,
    userSettingsDefaultRoomConfigId?: string | null,
  } | null,
};

export type DeleteUserSettingsMutationVariables = {
  input: DeleteUserSettingsInput,
  condition?: ModelUserSettingsConditionInput | null,
};

export type DeleteUserSettingsMutation = {
  deleteUserSettings?:  {
    __typename: "UserSettings",
    userId: string,
    mailConfig?:  {
      __typename: "MailConfig",
      userId: string,
      userMail: string,
      isReceiveOwnBooking?: boolean | null,
      isReceiveForeignBooking?: boolean | null,
      isReceiveMeetingRoomBooking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    defaultRoomConfig?:  {
      __typename: "DefaultRoomConfig",
      userId: string,
      roomId?: string | null,
      roomName?: string | null,
      neighborhoodId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    consent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    userSettingsMailConfigId?: string | null,
    userSettingsDefaultRoomConfigId?: string | null,
  } | null,
};

export type CreateInfoTextMutationVariables = {
  input: CreateInfoTextInput,
  condition?: ModelInfoTextConditionInput | null,
};

export type CreateInfoTextMutation = {
  createInfoText?:  {
    __typename: "InfoText",
    id: string,
    infoText: string,
    important: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateInfoTextMutationVariables = {
  input: UpdateInfoTextInput,
  condition?: ModelInfoTextConditionInput | null,
};

export type UpdateInfoTextMutation = {
  updateInfoText?:  {
    __typename: "InfoText",
    id: string,
    infoText: string,
    important: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteInfoTextMutationVariables = {
  input: DeleteInfoTextInput,
  condition?: ModelInfoTextConditionInput | null,
};

export type DeleteInfoTextMutation = {
  deleteInfoText?:  {
    __typename: "InfoText",
    id: string,
    infoText: string,
    important: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateHintMutationVariables = {
  input: CreateHintInput,
  condition?: ModelHintConditionInput | null,
};

export type CreateHintMutation = {
  createHint?:  {
    __typename: "Hint",
    hintId: string,
    roomId: string,
    buildingId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHintMutationVariables = {
  input: UpdateHintInput,
  condition?: ModelHintConditionInput | null,
};

export type UpdateHintMutation = {
  updateHint?:  {
    __typename: "Hint",
    hintId: string,
    roomId: string,
    buildingId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHintMutationVariables = {
  input: DeleteHintInput,
  condition?: ModelHintConditionInput | null,
};

export type DeleteHintMutation = {
  deleteHint?:  {
    __typename: "Hint",
    hintId: string,
    roomId: string,
    buildingId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPresignedRoomplanUrlQueryVariables = {
  writeAccess: boolean,
  roomId: string,
  roomplanId: string,
};

export type GetPresignedRoomplanUrlQuery = {
  getPresignedRoomplanUrl?: string | null,
};

export type GetPresignedImageUrlQueryVariables = {
  writeAccess: boolean,
  roomId: string,
  imageId: string,
};

export type GetPresignedImageUrlQuery = {
  getPresignedImageUrl?: string | null,
};

export type GetRoomsQuery = {
  getRooms?:  {
    __typename: "ModelRoomConnection",
    items:  Array< {
      __typename: "Room",
      roomId: string,
      buildingId?: string | null,
      orgUnitId?: string | null,
      orgUnitAdmin?: string | null,
      name: string,
      nameLowerCased: string,
      roomPlanExisting?: boolean | null,
      roomPlanId?: string | null,
      roomPlanScaleFactor?: number | null,
      isActive?: boolean | null,
      isTimeActive?: boolean | null,
      isBookingDisabled?: boolean | null,
      isDefault?: boolean | null,
      isPublic?: boolean | null,
      hasHints?: boolean | null,
      hasNeighborhood?: boolean | null,
      floor?: number | null,
      hasMeetingRooms?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingRoomsQueryVariables = {
  roomIds?: Array< string | null > | null,
};

export type GetMeetingRoomsQuery = {
  getMeetingRooms?:  {
    __typename: "ModelMeetingRoomConnection",
    items:  Array< {
      __typename: "MeetingRoom",
      meetingRoomId: string,
      roomId: string,
      name: string,
      nameLowerCased: string,
      hintId: string,
      type: string,
      capacity?: number | null,
      roleIds?: Array< string > | null,
      neighborhoodId: string,
      equipmentIds?: Array< string > | null,
      meetingRoomSeatConfIds?: Array< string > | null,
      isBookable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingRoomsByBuildingIdQueryVariables = {
  buildingId: string,
};

export type GetMeetingRoomsByBuildingIdQuery = {
  getMeetingRoomsByBuildingId?:  Array< {
    __typename: "MeetingRoom",
    meetingRoomId: string,
    roomId: string,
    name: string,
    nameLowerCased: string,
    hintId: string,
    type: string,
    capacity?: number | null,
    roleIds?: Array< string > | null,
    neighborhoodId: string,
    equipmentIds?: Array< string > | null,
    meetingRoomSeatConfIds?: Array< string > | null,
    isBookable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetMeetingRoomBookingsQueryVariables = {
  roomIds?: Array< string > | null,
  meetingRoomIds?: Array< string | null > | null,
  date?: string | null,
  dateTo?: string | null,
  bookerId?: string | null,
};

export type GetMeetingRoomBookingsQuery = {
  getMeetingRoomBookings?:  {
    __typename: "ModelMeetingRoomBookingConnection",
    items:  Array< {
      __typename: "MeetingRoomBooking",
      bookingId: string,
      date: string,
      meetingRoomId: string,
      roomId: string,
      bookerId: string,
      bookerName: string,
      bookerGivenName?: string | null,
      bookerFamilyName?: string | null,
      orgUnitId: string,
      timeBegin?: string | null,
      timeEnd?: string | null,
      meetingName?: string | null,
      roomCapacity?: number | null,
      participantNumber?: number | null,
      meetingType?: string | null,
      visitors?: string | null,
      meetingRoomSeatConfId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSeatBookingsQueryVariables = {
  input?: GetSeatBookingsInput | null,
};

export type GetSeatBookingsQuery = {
  getSeatBookings?:  {
    __typename: "ModelSeatBookingConnection",
    items:  Array< {
      __typename: "Booking",
      bookingId: string,
      date: string,
      seatId: string,
      roomId: string,
      bookerId: string,
      bookerName: string,
      bookerGivenName?: string | null,
      bookerFamilyName?: string | null,
      orgUnitId: string,
      orgUnitAdmin?: string | null,
      bookingFor?: string | null,
      timeBegin?: string | null,
      timeEnd?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHintsByBuildingQueryVariables = {
  buildingId: string,
};

export type GetHintsByBuildingQuery = {
  getHintsByBuilding?:  Array< {
    __typename: "Hint",
    hintId: string,
    roomId: string,
    buildingId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetInventoriesQuery = {
  getInventories?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      inventoryId: string,
      orgUnitAdmin?: string | null,
      orgUnitId: string,
      type: string,
      name: string,
      nameLowerCased: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSeatConfigsQueryVariables = {
  roomId: string,
};

export type GetSeatConfigsQuery = {
  getSeatConfigs?:  {
    __typename: "ModelSeatConfigConnection",
    items:  Array< {
      __typename: "SeatConfig",
      seatName: string,
      orgUnitAdmin?: string | null,
      roomId: string,
      seatType?: string | null,
      inventory: Array< string >,
      isSeatHeightAdjustable?: boolean | null,
      owner?: string | null,
      isOwnerRegistered?: boolean | null,
      neighborhoodId: string,
      isBookable?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInventoryByLowerCasedNameQueryVariables = {
  input?: InventoryByLowerCasedNameInput | null,
};

export type GetInventoryByLowerCasedNameQuery = {
  getInventoryByLowerCasedName?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      inventoryId: string,
      orgUnitAdmin?: string | null,
      orgUnitId: string,
      type: string,
      name: string,
      nameLowerCased: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNeighborhoodsByRoomIdQueryVariables = {
  roomId: string,
  checkPermissions?: boolean | null,
};

export type GetNeighborhoodsByRoomIdQuery = {
  getNeighborhoodsByRoomId?:  {
    __typename: "ModelNeighborhoodConnection",
    items:  Array< {
      __typename: "Neighborhood",
      neighborhoodId: string,
      roomId: string,
      restricted?: boolean | null,
      roleIds?: Array< string > | null,
      name?: string | null,
      adminRoleIds?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAllNeighborhoodsQuery = {
  getAllNeighborhoods?:  {
    __typename: "ModelNeighborhoodConnection",
    items:  Array< {
      __typename: "Neighborhood",
      neighborhoodId: string,
      roomId: string,
      restricted?: boolean | null,
      roleIds?: Array< string > | null,
      name?: string | null,
      adminRoleIds?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBuildingQueryVariables = {
  buildingId: string,
};

export type GetBuildingQuery = {
  getBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBuildingsQueryVariables = {
  buildingId?: string | null,
  filter?: ModelBuildingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBuildingsQuery = {
  listBuildings?:  {
    __typename: "ModelBuildingConnection",
    items:  Array< {
      __typename: "Building",
      buildingId: string,
      buildingName: string,
      roleIds: Array< string >,
      buildingNameLowerCased: string,
      seatConfEmail?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRoomQueryVariables = {
  roomId: string,
};

export type GetRoomQuery = {
  getRoom?:  {
    __typename: "Room",
    roomId: string,
    buildingId?: string | null,
    orgUnitId?: string | null,
    orgUnitAdmin?: string | null,
    name: string,
    nameLowerCased: string,
    roomPlanExisting?: boolean | null,
    roomPlanId?: string | null,
    roomPlanScaleFactor?: number | null,
    seats?:  {
      __typename: "ModelSeatConfigConnection",
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    isTimeActive?: boolean | null,
    isBookingDisabled?: boolean | null,
    isDefault?: boolean | null,
    isPublic?: boolean | null,
    hasHints?: boolean | null,
    hasNeighborhood?: boolean | null,
    floor?: number | null,
    hasMeetingRooms?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRoomsQueryVariables = {
  roomId?: string | null,
  filter?: ModelRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRoomsQuery = {
  listRooms?:  {
    __typename: "ModelRoomConnection",
    items:  Array< {
      __typename: "Room",
      roomId: string,
      buildingId?: string | null,
      orgUnitId?: string | null,
      orgUnitAdmin?: string | null,
      name: string,
      nameLowerCased: string,
      roomPlanExisting?: boolean | null,
      roomPlanId?: string | null,
      roomPlanScaleFactor?: number | null,
      isActive?: boolean | null,
      isTimeActive?: boolean | null,
      isBookingDisabled?: boolean | null,
      isDefault?: boolean | null,
      isPublic?: boolean | null,
      hasHints?: boolean | null,
      hasNeighborhood?: boolean | null,
      floor?: number | null,
      hasMeetingRooms?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingRoomQueryVariables = {
  meetingRoomId: string,
  roomId: string,
};

export type GetMeetingRoomQuery = {
  getMeetingRoom?:  {
    __typename: "MeetingRoom",
    meetingRoomId: string,
    roomId: string,
    name: string,
    nameLowerCased: string,
    hintId: string,
    type: string,
    capacity?: number | null,
    roleIds?: Array< string > | null,
    neighborhoodId: string,
    equipmentIds?: Array< string > | null,
    meetingRoomSeatConfIds?: Array< string > | null,
    isBookable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMeetingRoomsQueryVariables = {
  meetingRoomId?: string | null,
  roomId?: ModelIDKeyConditionInput | null,
  filter?: ModelMeetingRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMeetingRoomsQuery = {
  listMeetingRooms?:  {
    __typename: "ModelMeetingRoomConnection",
    items:  Array< {
      __typename: "MeetingRoom",
      meetingRoomId: string,
      roomId: string,
      name: string,
      nameLowerCased: string,
      hintId: string,
      type: string,
      capacity?: number | null,
      roleIds?: Array< string > | null,
      neighborhoodId: string,
      equipmentIds?: Array< string > | null,
      meetingRoomSeatConfIds?: Array< string > | null,
      isBookable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNeighborhoodQueryVariables = {
  neighborhoodId: string,
  roomId: string,
};

export type GetNeighborhoodQuery = {
  getNeighborhood?:  {
    __typename: "Neighborhood",
    neighborhoodId: string,
    roomId: string,
    restricted?: boolean | null,
    roleIds?: Array< string > | null,
    name?: string | null,
    adminRoleIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNeighborhoodsQueryVariables = {
  neighborhoodId?: string | null,
  roomId?: ModelIDKeyConditionInput | null,
  filter?: ModelNeighborhoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNeighborhoodsQuery = {
  listNeighborhoods?:  {
    __typename: "ModelNeighborhoodConnection",
    items:  Array< {
      __typename: "Neighborhood",
      neighborhoodId: string,
      roomId: string,
      restricted?: boolean | null,
      roleIds?: Array< string > | null,
      name?: string | null,
      adminRoleIds?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookingQueryVariables = {
  bookingId: string,
  date: string,
};

export type GetBookingQuery = {
  getBooking?:  {
    __typename: "Booking",
    bookingId: string,
    date: string,
    seatId: string,
    roomId: string,
    room?:  {
      __typename: "Room",
      roomId: string,
      buildingId?: string | null,
      orgUnitId?: string | null,
      orgUnitAdmin?: string | null,
      name: string,
      nameLowerCased: string,
      roomPlanExisting?: boolean | null,
      roomPlanId?: string | null,
      roomPlanScaleFactor?: number | null,
      isActive?: boolean | null,
      isTimeActive?: boolean | null,
      isBookingDisabled?: boolean | null,
      isDefault?: boolean | null,
      isPublic?: boolean | null,
      hasHints?: boolean | null,
      hasNeighborhood?: boolean | null,
      floor?: number | null,
      hasMeetingRooms?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookerId: string,
    bookerName: string,
    bookerGivenName?: string | null,
    bookerFamilyName?: string | null,
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    bookingFor?: string | null,
    timeBegin?: string | null,
    timeEnd?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBookingsQueryVariables = {
  bookingId?: string | null,
  date?: ModelStringKeyConditionInput | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBookingsQuery = {
  listBookings?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      bookingId: string,
      date: string,
      seatId: string,
      roomId: string,
      bookerId: string,
      bookerName: string,
      bookerGivenName?: string | null,
      bookerFamilyName?: string | null,
      orgUnitId: string,
      orgUnitAdmin?: string | null,
      bookingFor?: string | null,
      timeBegin?: string | null,
      timeEnd?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingRoomBookingQueryVariables = {
  bookingId: string,
  date: string,
};

export type GetMeetingRoomBookingQuery = {
  getMeetingRoomBooking?:  {
    __typename: "MeetingRoomBooking",
    bookingId: string,
    date: string,
    meetingRoomId: string,
    roomId: string,
    bookerId: string,
    bookerName: string,
    bookerGivenName?: string | null,
    bookerFamilyName?: string | null,
    orgUnitId: string,
    timeBegin?: string | null,
    timeEnd?: string | null,
    meetingName?: string | null,
    roomCapacity?: number | null,
    participantNumber?: number | null,
    meetingType?: string | null,
    visitors?: string | null,
    meetingRoomSeatConfId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMeetingRoomBookingsQueryVariables = {
  bookingId?: string | null,
  date?: ModelStringKeyConditionInput | null,
  filter?: ModelMeetingRoomBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMeetingRoomBookingsQuery = {
  listMeetingRoomBookings?:  {
    __typename: "ModelMeetingRoomBookingConnection",
    items:  Array< {
      __typename: "MeetingRoomBooking",
      bookingId: string,
      date: string,
      meetingRoomId: string,
      roomId: string,
      bookerId: string,
      bookerName: string,
      bookerGivenName?: string | null,
      bookerFamilyName?: string | null,
      orgUnitId: string,
      timeBegin?: string | null,
      timeEnd?: string | null,
      meetingName?: string | null,
      roomCapacity?: number | null,
      participantNumber?: number | null,
      meetingType?: string | null,
      visitors?: string | null,
      meetingRoomSeatConfId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInventoryQueryVariables = {
  inventoryId: string,
};

export type GetInventoryQuery = {
  getInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInventoriesQueryVariables = {
  inventoryId?: string | null,
  filter?: ModelInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInventoriesQuery = {
  listInventories?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      inventoryId: string,
      orgUnitAdmin?: string | null,
      orgUnitId: string,
      type: string,
      name: string,
      nameLowerCased: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingRoomEquipmentQueryVariables = {
  equipmentId: string,
};

export type GetMeetingRoomEquipmentQuery = {
  getMeetingRoomEquipment?:  {
    __typename: "MeetingRoomEquipment",
    equipmentId: string,
    buildingId: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMeetingRoomEquipmentsQueryVariables = {
  equipmentId?: string | null,
  filter?: ModelMeetingRoomEquipmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMeetingRoomEquipmentsQuery = {
  listMeetingRoomEquipments?:  {
    __typename: "ModelMeetingRoomEquipmentConnection",
    items:  Array< {
      __typename: "MeetingRoomEquipment",
      equipmentId: string,
      buildingId: string,
      name: string,
      nameLowerCased: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingRoomSeatConfigQueryVariables = {
  meetingRoomSeatConfId: string,
};

export type GetMeetingRoomSeatConfigQuery = {
  getMeetingRoomSeatConfig?:  {
    __typename: "MeetingRoomSeatConfig",
    meetingRoomSeatConfId: string,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name: string,
    imageId?: string | null,
    daysInAdvance: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMeetingRoomSeatConfigsQueryVariables = {
  meetingRoomSeatConfId?: string | null,
  filter?: ModelMeetingRoomSeatConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMeetingRoomSeatConfigsQuery = {
  listMeetingRoomSeatConfigs?:  {
    __typename: "ModelMeetingRoomSeatConfigConnection",
    items:  Array< {
      __typename: "MeetingRoomSeatConfig",
      meetingRoomSeatConfId: string,
      buildingId: string,
      roomId: string,
      meetingRoomId: string,
      name: string,
      imageId?: string | null,
      daysInAdvance: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSeatConfigQueryVariables = {
  roomId: string,
  seatName: string,
};

export type GetSeatConfigQuery = {
  getSeatConfig?:  {
    __typename: "SeatConfig",
    seatName: string,
    orgUnitAdmin?: string | null,
    roomId: string,
    seatType?: string | null,
    inventory: Array< string >,
    isSeatHeightAdjustable?: boolean | null,
    owner?: string | null,
    isOwnerRegistered?: boolean | null,
    neighborhoodId: string,
    isBookable?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSeatConfigsQueryVariables = {
  roomId?: string | null,
  seatName?: ModelStringKeyConditionInput | null,
  filter?: ModelSeatConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSeatConfigsQuery = {
  listSeatConfigs?:  {
    __typename: "ModelSeatConfigConnection",
    items:  Array< {
      __typename: "SeatConfig",
      seatName: string,
      orgUnitAdmin?: string | null,
      roomId: string,
      seatType?: string | null,
      inventory: Array< string >,
      isSeatHeightAdjustable?: boolean | null,
      owner?: string | null,
      isOwnerRegistered?: boolean | null,
      neighborhoodId: string,
      isBookable?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookingConfigQueryVariables = {
  orgUnitId: string,
};

export type GetBookingConfigQuery = {
  getBookingConfig?:  {
    __typename: "BookingConfig",
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    maxDaysInAdvance?: number | null,
    maxBookableDays?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBookingConfigsQueryVariables = {
  orgUnitId?: string | null,
  filter?: ModelBookingConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBookingConfigsQuery = {
  listBookingConfigs?:  {
    __typename: "ModelBookingConfigConnection",
    items:  Array< {
      __typename: "BookingConfig",
      orgUnitId: string,
      orgUnitAdmin?: string | null,
      maxDaysInAdvance?: number | null,
      maxBookableDays?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMailConfigQueryVariables = {
  userId: string,
};

export type GetMailConfigQuery = {
  getMailConfig?:  {
    __typename: "MailConfig",
    userId: string,
    userMail: string,
    isReceiveOwnBooking?: boolean | null,
    isReceiveForeignBooking?: boolean | null,
    isReceiveMeetingRoomBooking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMailConfigsQueryVariables = {
  userId?: string | null,
  filter?: ModelMailConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMailConfigsQuery = {
  listMailConfigs?:  {
    __typename: "ModelMailConfigConnection",
    items:  Array< {
      __typename: "MailConfig",
      userId: string,
      userMail: string,
      isReceiveOwnBooking?: boolean | null,
      isReceiveForeignBooking?: boolean | null,
      isReceiveMeetingRoomBooking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDefaultRoomConfigQueryVariables = {
  userId: string,
};

export type GetDefaultRoomConfigQuery = {
  getDefaultRoomConfig?:  {
    __typename: "DefaultRoomConfig",
    userId: string,
    roomId?: string | null,
    roomName?: string | null,
    neighborhoodId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDefaultRoomConfigsQueryVariables = {
  userId?: string | null,
  filter?: ModelDefaultRoomConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDefaultRoomConfigsQuery = {
  listDefaultRoomConfigs?:  {
    __typename: "ModelDefaultRoomConfigConnection",
    items:  Array< {
      __typename: "DefaultRoomConfig",
      userId: string,
      roomId?: string | null,
      roomName?: string | null,
      neighborhoodId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserSettingsQueryVariables = {
  userId: string,
};

export type GetUserSettingsQuery = {
  getUserSettings?:  {
    __typename: "UserSettings",
    userId: string,
    mailConfig?:  {
      __typename: "MailConfig",
      userId: string,
      userMail: string,
      isReceiveOwnBooking?: boolean | null,
      isReceiveForeignBooking?: boolean | null,
      isReceiveMeetingRoomBooking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    defaultRoomConfig?:  {
      __typename: "DefaultRoomConfig",
      userId: string,
      roomId?: string | null,
      roomName?: string | null,
      neighborhoodId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    consent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    userSettingsMailConfigId?: string | null,
    userSettingsDefaultRoomConfigId?: string | null,
  } | null,
};

export type ListUserSettingsQueryVariables = {
  userId?: string | null,
  filter?: ModelUserSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserSettingsQuery = {
  listUserSettings?:  {
    __typename: "ModelUserSettingsConnection",
    items:  Array< {
      __typename: "UserSettings",
      userId: string,
      consent?: boolean | null,
      createdAt: string,
      updatedAt: string,
      userSettingsMailConfigId?: string | null,
      userSettingsDefaultRoomConfigId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInfoTextQueryVariables = {
  id: string,
};

export type GetInfoTextQuery = {
  getInfoText?:  {
    __typename: "InfoText",
    id: string,
    infoText: string,
    important: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListInfoTextsQueryVariables = {
  id?: string | null,
  filter?: ModelInfoTextFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInfoTextsQuery = {
  listInfoTexts?:  {
    __typename: "ModelInfoTextConnection",
    items:  Array< {
      __typename: "InfoText",
      id: string,
      infoText: string,
      important: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RoomByBuildingIdQueryVariables = {
  buildingId: string,
  nameLowerCased?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RoomByBuildingIdQuery = {
  roomByBuildingId?:  {
    __typename: "ModelRoomConnection",
    items:  Array< {
      __typename: "Room",
      roomId: string,
      buildingId?: string | null,
      orgUnitId?: string | null,
      orgUnitAdmin?: string | null,
      name: string,
      nameLowerCased: string,
      roomPlanExisting?: boolean | null,
      roomPlanId?: string | null,
      roomPlanScaleFactor?: number | null,
      isActive?: boolean | null,
      isTimeActive?: boolean | null,
      isBookingDisabled?: boolean | null,
      isDefault?: boolean | null,
      isPublic?: boolean | null,
      hasHints?: boolean | null,
      hasNeighborhood?: boolean | null,
      floor?: number | null,
      hasMeetingRooms?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RoomByNameAndOrgUnitIDQueryVariables = {
  orgUnitId: string,
  nameLowerCased?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RoomByNameAndOrgUnitIDQuery = {
  roomByNameAndOrgUnitID?:  {
    __typename: "ModelRoomConnection",
    items:  Array< {
      __typename: "Room",
      roomId: string,
      buildingId?: string | null,
      orgUnitId?: string | null,
      orgUnitAdmin?: string | null,
      name: string,
      nameLowerCased: string,
      roomPlanExisting?: boolean | null,
      roomPlanId?: string | null,
      roomPlanScaleFactor?: number | null,
      isActive?: boolean | null,
      isTimeActive?: boolean | null,
      isBookingDisabled?: boolean | null,
      isDefault?: boolean | null,
      isPublic?: boolean | null,
      hasHints?: boolean | null,
      hasNeighborhood?: boolean | null,
      floor?: number | null,
      hasMeetingRooms?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EquipmentByBuildingIdAndNameLowerCasedQueryVariables = {
  nameLowerCased: string,
  buildingId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingRoomEquipmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EquipmentByBuildingIdAndNameLowerCasedQuery = {
  equipmentByBuildingIdAndNameLowerCased?:  {
    __typename: "ModelMeetingRoomEquipmentConnection",
    items:  Array< {
      __typename: "MeetingRoomEquipment",
      equipmentId: string,
      buildingId: string,
      name: string,
      nameLowerCased: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SeatConfigBySeatNameQueryVariables = {
  seatName: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSeatConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SeatConfigBySeatNameQuery = {
  seatConfigBySeatName?:  {
    __typename: "ModelSeatConfigConnection",
    items:  Array< {
      __typename: "SeatConfig",
      seatName: string,
      orgUnitAdmin?: string | null,
      roomId: string,
      seatType?: string | null,
      inventory: Array< string >,
      isSeatHeightAdjustable?: boolean | null,
      owner?: string | null,
      isOwnerRegistered?: boolean | null,
      neighborhoodId: string,
      isBookable?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHintQueryVariables = {
  hintId: string,
  roomId: string,
};

export type GetHintQuery = {
  getHint?:  {
    __typename: "Hint",
    hintId: string,
    roomId: string,
    buildingId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHintsQueryVariables = {
  hintId?: string | null,
  roomId?: ModelIDKeyConditionInput | null,
  filter?: ModelHintFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHintsQuery = {
  listHints?:  {
    __typename: "ModelHintConnection",
    items:  Array< {
      __typename: "Hint",
      hintId: string,
      roomId: string,
      buildingId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HintsByRoomQueryVariables = {
  roomId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHintFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HintsByRoomQuery = {
  hintsByRoom?:  {
    __typename: "ModelHintConnection",
    items:  Array< {
      __typename: "Hint",
      hintId: string,
      roomId: string,
      buildingId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HintByBuildingIdQueryVariables = {
  buildingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHintFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HintByBuildingIdQuery = {
  hintByBuildingId?:  {
    __typename: "ModelHintConnection",
    items:  Array< {
      __typename: "Hint",
      hintId: string,
      roomId: string,
      buildingId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnChangeRoomListSubscription = {
  onChangeRoomList?:  {
    __typename: "Room",
    roomId: string,
    buildingId?: string | null,
    orgUnitId?: string | null,
    orgUnitAdmin?: string | null,
    name: string,
    nameLowerCased: string,
    roomPlanExisting?: boolean | null,
    roomPlanId?: string | null,
    roomPlanScaleFactor?: number | null,
    seats?:  {
      __typename: "ModelSeatConfigConnection",
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    isTimeActive?: boolean | null,
    isBookingDisabled?: boolean | null,
    isDefault?: boolean | null,
    isPublic?: boolean | null,
    hasHints?: boolean | null,
    hasNeighborhood?: boolean | null,
    floor?: number | null,
    hasMeetingRooms?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnChangeSeatBookingSubscription = {
  onChangeSeatBooking?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type OnChangeMeetingRoomBookingSubscription = {
  onChangeMeetingRoomBooking?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type OnChangeBuildingsSubscription = {
  onChangeBuildings?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnChangeInventoriesSubscription = {
  onChangeInventories?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnChangeHintsByRoomSubscriptionVariables = {
  roomId: string,
};

export type OnChangeHintsByRoomSubscription = {
  onChangeHintsByRoom?:  {
    __typename: "Hint",
    hintId: string,
    roomId: string,
    buildingId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeleteNeighborhoodsSubscription = {
  onCreateDeleteNeighborhoods?:  {
    __typename: "GenericMutationOutput",
    response?: string | null,
  } | null,
};

export type OnUpdateSecureMeetingRoomSubscription = {
  onUpdateSecureMeetingRoom?:  {
    __typename: "MeetingRoom",
    meetingRoomId: string,
    roomId: string,
    name: string,
    nameLowerCased: string,
    hintId: string,
    type: string,
    capacity?: number | null,
    roleIds?: Array< string > | null,
    neighborhoodId: string,
    equipmentIds?: Array< string > | null,
    meetingRoomSeatConfIds?: Array< string > | null,
    isBookable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRestrictedNeighborhoodsSubscription = {
  onUpdateRestrictedNeighborhoods?:  {
    __typename: "UpdateRestrictedNeighborhoodsOutput",
    statusCode?: number | null,
    message?: string | null,
  } | null,
};

export type OnCreateBuildingSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingFilterInput | null,
};

export type OnCreateBuildingSubscription = {
  onCreateBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBuildingSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingFilterInput | null,
};

export type OnUpdateBuildingSubscription = {
  onUpdateBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBuildingSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingFilterInput | null,
};

export type OnDeleteBuildingSubscription = {
  onDeleteBuilding?:  {
    __typename: "Building",
    buildingId: string,
    buildingName: string,
    rooms?:  {
      __typename: "ModelRoomConnection",
      nextToken?: string | null,
    } | null,
    roleIds: Array< string >,
    buildingNameLowerCased: string,
    seatConfEmail?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInventorySubscriptionVariables = {
  filter?: ModelSubscriptionInventoryFilterInput | null,
};

export type OnCreateInventorySubscription = {
  onCreateInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInventorySubscriptionVariables = {
  filter?: ModelSubscriptionInventoryFilterInput | null,
};

export type OnUpdateInventorySubscription = {
  onUpdateInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInventorySubscriptionVariables = {
  filter?: ModelSubscriptionInventoryFilterInput | null,
};

export type OnDeleteInventorySubscription = {
  onDeleteInventory?:  {
    __typename: "Inventory",
    inventoryId: string,
    orgUnitAdmin?: string | null,
    orgUnitId: string,
    type: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMeetingRoomEquipmentSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingRoomEquipmentFilterInput | null,
};

export type OnCreateMeetingRoomEquipmentSubscription = {
  onCreateMeetingRoomEquipment?:  {
    __typename: "MeetingRoomEquipment",
    equipmentId: string,
    buildingId: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMeetingRoomEquipmentSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingRoomEquipmentFilterInput | null,
};

export type OnUpdateMeetingRoomEquipmentSubscription = {
  onUpdateMeetingRoomEquipment?:  {
    __typename: "MeetingRoomEquipment",
    equipmentId: string,
    buildingId: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMeetingRoomEquipmentSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingRoomEquipmentFilterInput | null,
};

export type OnDeleteMeetingRoomEquipmentSubscription = {
  onDeleteMeetingRoomEquipment?:  {
    __typename: "MeetingRoomEquipment",
    equipmentId: string,
    buildingId: string,
    name: string,
    nameLowerCased: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMeetingRoomSeatConfigSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingRoomSeatConfigFilterInput | null,
};

export type OnCreateMeetingRoomSeatConfigSubscription = {
  onCreateMeetingRoomSeatConfig?:  {
    __typename: "MeetingRoomSeatConfig",
    meetingRoomSeatConfId: string,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name: string,
    imageId?: string | null,
    daysInAdvance: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMeetingRoomSeatConfigSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingRoomSeatConfigFilterInput | null,
};

export type OnUpdateMeetingRoomSeatConfigSubscription = {
  onUpdateMeetingRoomSeatConfig?:  {
    __typename: "MeetingRoomSeatConfig",
    meetingRoomSeatConfId: string,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name: string,
    imageId?: string | null,
    daysInAdvance: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMeetingRoomSeatConfigSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingRoomSeatConfigFilterInput | null,
};

export type OnDeleteMeetingRoomSeatConfigSubscription = {
  onDeleteMeetingRoomSeatConfig?:  {
    __typename: "MeetingRoomSeatConfig",
    meetingRoomSeatConfId: string,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name: string,
    imageId?: string | null,
    daysInAdvance: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSeatConfigSubscriptionVariables = {
  filter?: ModelSubscriptionSeatConfigFilterInput | null,
};

export type OnCreateSeatConfigSubscription = {
  onCreateSeatConfig?:  {
    __typename: "SeatConfig",
    seatName: string,
    orgUnitAdmin?: string | null,
    roomId: string,
    seatType?: string | null,
    inventory: Array< string >,
    isSeatHeightAdjustable?: boolean | null,
    owner?: string | null,
    isOwnerRegistered?: boolean | null,
    neighborhoodId: string,
    isBookable?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSeatConfigSubscriptionVariables = {
  filter?: ModelSubscriptionSeatConfigFilterInput | null,
};

export type OnUpdateSeatConfigSubscription = {
  onUpdateSeatConfig?:  {
    __typename: "SeatConfig",
    seatName: string,
    orgUnitAdmin?: string | null,
    roomId: string,
    seatType?: string | null,
    inventory: Array< string >,
    isSeatHeightAdjustable?: boolean | null,
    owner?: string | null,
    isOwnerRegistered?: boolean | null,
    neighborhoodId: string,
    isBookable?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSeatConfigSubscriptionVariables = {
  filter?: ModelSubscriptionSeatConfigFilterInput | null,
};

export type OnDeleteSeatConfigSubscription = {
  onDeleteSeatConfig?:  {
    __typename: "SeatConfig",
    seatName: string,
    orgUnitAdmin?: string | null,
    roomId: string,
    seatType?: string | null,
    inventory: Array< string >,
    isSeatHeightAdjustable?: boolean | null,
    owner?: string | null,
    isOwnerRegistered?: boolean | null,
    neighborhoodId: string,
    isBookable?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBookingConfigSubscriptionVariables = {
  filter?: ModelSubscriptionBookingConfigFilterInput | null,
};

export type OnCreateBookingConfigSubscription = {
  onCreateBookingConfig?:  {
    __typename: "BookingConfig",
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    maxDaysInAdvance?: number | null,
    maxBookableDays?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBookingConfigSubscriptionVariables = {
  filter?: ModelSubscriptionBookingConfigFilterInput | null,
};

export type OnUpdateBookingConfigSubscription = {
  onUpdateBookingConfig?:  {
    __typename: "BookingConfig",
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    maxDaysInAdvance?: number | null,
    maxBookableDays?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBookingConfigSubscriptionVariables = {
  filter?: ModelSubscriptionBookingConfigFilterInput | null,
};

export type OnDeleteBookingConfigSubscription = {
  onDeleteBookingConfig?:  {
    __typename: "BookingConfig",
    orgUnitId: string,
    orgUnitAdmin?: string | null,
    maxDaysInAdvance?: number | null,
    maxBookableDays?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMailConfigSubscriptionVariables = {
  filter?: ModelSubscriptionMailConfigFilterInput | null,
  userId?: string | null,
};

export type OnCreateMailConfigSubscription = {
  onCreateMailConfig?:  {
    __typename: "MailConfig",
    userId: string,
    userMail: string,
    isReceiveOwnBooking?: boolean | null,
    isReceiveForeignBooking?: boolean | null,
    isReceiveMeetingRoomBooking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMailConfigSubscriptionVariables = {
  filter?: ModelSubscriptionMailConfigFilterInput | null,
  userId?: string | null,
};

export type OnUpdateMailConfigSubscription = {
  onUpdateMailConfig?:  {
    __typename: "MailConfig",
    userId: string,
    userMail: string,
    isReceiveOwnBooking?: boolean | null,
    isReceiveForeignBooking?: boolean | null,
    isReceiveMeetingRoomBooking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMailConfigSubscriptionVariables = {
  filter?: ModelSubscriptionMailConfigFilterInput | null,
  userId?: string | null,
};

export type OnDeleteMailConfigSubscription = {
  onDeleteMailConfig?:  {
    __typename: "MailConfig",
    userId: string,
    userMail: string,
    isReceiveOwnBooking?: boolean | null,
    isReceiveForeignBooking?: boolean | null,
    isReceiveMeetingRoomBooking?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDefaultRoomConfigSubscriptionVariables = {
  filter?: ModelSubscriptionDefaultRoomConfigFilterInput | null,
  userId?: string | null,
};

export type OnCreateDefaultRoomConfigSubscription = {
  onCreateDefaultRoomConfig?:  {
    __typename: "DefaultRoomConfig",
    userId: string,
    roomId?: string | null,
    roomName?: string | null,
    neighborhoodId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDefaultRoomConfigSubscriptionVariables = {
  filter?: ModelSubscriptionDefaultRoomConfigFilterInput | null,
  userId?: string | null,
};

export type OnUpdateDefaultRoomConfigSubscription = {
  onUpdateDefaultRoomConfig?:  {
    __typename: "DefaultRoomConfig",
    userId: string,
    roomId?: string | null,
    roomName?: string | null,
    neighborhoodId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDefaultRoomConfigSubscriptionVariables = {
  filter?: ModelSubscriptionDefaultRoomConfigFilterInput | null,
  userId?: string | null,
};

export type OnDeleteDefaultRoomConfigSubscription = {
  onDeleteDefaultRoomConfig?:  {
    __typename: "DefaultRoomConfig",
    userId: string,
    roomId?: string | null,
    roomName?: string | null,
    neighborhoodId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingsFilterInput | null,
  userId?: string | null,
};

export type OnCreateUserSettingsSubscription = {
  onCreateUserSettings?:  {
    __typename: "UserSettings",
    userId: string,
    mailConfig?:  {
      __typename: "MailConfig",
      userId: string,
      userMail: string,
      isReceiveOwnBooking?: boolean | null,
      isReceiveForeignBooking?: boolean | null,
      isReceiveMeetingRoomBooking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    defaultRoomConfig?:  {
      __typename: "DefaultRoomConfig",
      userId: string,
      roomId?: string | null,
      roomName?: string | null,
      neighborhoodId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    consent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    userSettingsMailConfigId?: string | null,
    userSettingsDefaultRoomConfigId?: string | null,
  } | null,
};

export type OnUpdateUserSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingsFilterInput | null,
  userId?: string | null,
};

export type OnUpdateUserSettingsSubscription = {
  onUpdateUserSettings?:  {
    __typename: "UserSettings",
    userId: string,
    mailConfig?:  {
      __typename: "MailConfig",
      userId: string,
      userMail: string,
      isReceiveOwnBooking?: boolean | null,
      isReceiveForeignBooking?: boolean | null,
      isReceiveMeetingRoomBooking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    defaultRoomConfig?:  {
      __typename: "DefaultRoomConfig",
      userId: string,
      roomId?: string | null,
      roomName?: string | null,
      neighborhoodId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    consent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    userSettingsMailConfigId?: string | null,
    userSettingsDefaultRoomConfigId?: string | null,
  } | null,
};

export type OnDeleteUserSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingsFilterInput | null,
  userId?: string | null,
};

export type OnDeleteUserSettingsSubscription = {
  onDeleteUserSettings?:  {
    __typename: "UserSettings",
    userId: string,
    mailConfig?:  {
      __typename: "MailConfig",
      userId: string,
      userMail: string,
      isReceiveOwnBooking?: boolean | null,
      isReceiveForeignBooking?: boolean | null,
      isReceiveMeetingRoomBooking?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    defaultRoomConfig?:  {
      __typename: "DefaultRoomConfig",
      userId: string,
      roomId?: string | null,
      roomName?: string | null,
      neighborhoodId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    consent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    userSettingsMailConfigId?: string | null,
    userSettingsDefaultRoomConfigId?: string | null,
  } | null,
};

export type OnCreateInfoTextSubscriptionVariables = {
  filter?: ModelSubscriptionInfoTextFilterInput | null,
};

export type OnCreateInfoTextSubscription = {
  onCreateInfoText?:  {
    __typename: "InfoText",
    id: string,
    infoText: string,
    important: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateInfoTextSubscriptionVariables = {
  filter?: ModelSubscriptionInfoTextFilterInput | null,
};

export type OnUpdateInfoTextSubscription = {
  onUpdateInfoText?:  {
    __typename: "InfoText",
    id: string,
    infoText: string,
    important: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteInfoTextSubscriptionVariables = {
  filter?: ModelSubscriptionInfoTextFilterInput | null,
};

export type OnDeleteInfoTextSubscription = {
  onDeleteInfoText?:  {
    __typename: "InfoText",
    id: string,
    infoText: string,
    important: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};
