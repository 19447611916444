import {gql, useQuery} from "@apollo/client";
import {getPresignedImageUrl, getPresignedRoomplanUrl} from "../graphql/queries";

function imageIdGenerator() {
    return Math.random().toString(36).substring(2, 10);
}

export const useFileUpload = () => {
    const {refetch: refetchPreSignedUrl} = useQuery(gql(getPresignedImageUrl));

    const uploadImage = async (roomId: string, file: File) => {
        const imageId = imageIdGenerator()

        const preSignedUrlResponse = await refetchPreSignedUrl({
            writeAccess: true,
            roomId: roomId,
            imageId: imageId
        }).catch((err) => console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmationFirst"))

        if (!preSignedUrlResponse || !preSignedUrlResponse.data || !preSignedUrlResponse.data.getPresignedImageUrl) {
            return
        }

        const preSignedUrl = preSignedUrlResponse.data.getPresignedImageUrl
        await fetch(preSignedUrl, {
            method: "PUT",
            body: file
        }).catch((err) => {
            console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmation")
            return
        })
        return imageId
    }

    const getImageUrl = async (roomId: string, imageId: string) => {
        const preSignedUrlResponse = await refetchPreSignedUrl({
            writeAccess: false,
            roomId: roomId,
            imageId: imageId
        }).catch((err) => console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmationFirst"))

        if (!preSignedUrlResponse || !preSignedUrlResponse.data || !preSignedUrlResponse.data.getPresignedImageUrl) {
            return
        }

        const preSignedUrl = preSignedUrlResponse.data.getPresignedImageUrl
        return preSignedUrl
    }

    return {
        uploadImage,
        getImageUrl
    }
}