import React, {useEffect, useState} from "react";
import {
    FormHelperText,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import borders from "../../styles/borders";
import MeetingRoomBookingDropdown from "./MeetingRoomBookingDropdown";
import {MeetingRoomType, MeetingType} from "../../Utils/Enums";
import {useTranslation} from "react-i18next";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import ImagePreviewDialog from "./ImagePreviewDialog";
import dayjs from "dayjs";
import {useMeetingRoomSeatConfigurations} from "../../hooks/useMeetingRoomSeatConfigurations";

interface MeetingRoomBookingSettingsProps {
    roomId: string,
    meetingRoomId: string,
    maxParticipants: number | null
    meetingRoomType: MeetingRoomType
    settings: MeetingRoomBookingSettingsData
    setSettings: (newData: Partial<MeetingRoomBookingSettingsData>) => void
    selectedDates: Date[]
    setCurrentSeatConfigSetting: (seatConfig: SeatOptionI | undefined) => void
}

export interface MeetingRoomBookingSettingsData {
    meetingName: string
    participantNumber: number | null
    meetingType: MeetingType
    visitors: string
    seatConfId?: string | null
}

export interface SeatOptionI {
    seatConfId?: string;
    name: string;
    image?: string;
    daysInAdvance: number;
}

function MeetingRoomBookingSettings(props: MeetingRoomBookingSettingsProps) {
    const initialOption = 'no-change';
    const {getSeatConfigurations} = useMeetingRoomSeatConfigurations();
    const {roomId, meetingRoomId, maxParticipants, meetingRoomType, settings, setSettings, selectedDates} = props;
    const {isNoFullscreen} = useDeviceMediaType();
    const [selectedOption, setSelectedOption] = useState(initialOption);
    const [currentSeatConfig, setCurrentSeatConfig] = useState<SeatOptionI | undefined>(undefined);
    const [isDaysInAdvanceNotPossible, setIsDaysInAdvanceNotPossible] = useState<boolean>(false);
    const [seatConfigs, setSeatConfigs] = useState<SeatOptionI[]>([]);

    useEffect(() => {
        if (roomId && meetingRoomId) {
            getSeatConfigurations(roomId, meetingRoomId).then((result) => {
                setSeatConfigs(result.map((seatConfig) => {
                    return {
                        seatConfId: seatConfig.meetingRoomSeatConfId,
                        name: seatConfig.name,
                        image: seatConfig.imageUrl,
                        daysInAdvance: seatConfig.daysInAdvance
                    }
                }));
            })
        }
    }, [roomId, meetingRoomId, getSeatConfigurations]);

    useEffect(() => {
        if (props.settings.seatConfId !== null) {
            setSelectedOption(seatConfigs.find((seatConfig) => seatConfig.seatConfId === props.settings.seatConfId)?.name ?? initialOption);
        }
    }, [props.settings.seatConfId, seatConfigs]);

    useEffect(() => {
        if(selectedOption === initialOption) {
            setIsDaysInAdvanceNotPossible(false);
        } else {
            if(currentSeatConfig) {
                setIsDaysInAdvanceNotPossible(currentSeatConfig.daysInAdvance > getDifferenceFromTodayToMeetingDay());
            }
        }
    }, [currentSeatConfig, selectedOption]);

    useEffect(() => {
        if(selectedOption) {
            const seatConfig = findSeatConfig(selectedOption);
            setCurrentSeatConfig(seatConfig);
            props.setCurrentSeatConfigSetting(seatConfig);
       }
   }, [selectedOption]);

    const getDifferenceFromTodayToMeetingDay = () => {
        const today = dayjs();
        const meetingDate = dayjs(selectedDates[0]);
        const differenceInDays = meetingDate.diff(today, 'day');

        return differenceInDays;
    }

    const findSeatConfig = (value: string) => {
        return seatConfigs.find((seatConfig) => seatConfig.name === value);
    }

    const handleChange = (event: any) => {
        setSelectedOption(event.target.value);
        const seatConfig = findSeatConfig(event.target.value);
        setSettings({seatConfId: seatConfig?.seatConfId});
    };

    const disableMenuItem = (menuItem: SeatOptionI): boolean => {
        if(selectedDates.length > 0) {
            return menuItem.daysInAdvance > getDifferenceFromTodayToMeetingDay();
        } else {
            // no date selected
            return false;
        }
    }


    const handleMeetingNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setSettings({meetingName: event.target.value});
    }

    const handleVisitorsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setSettings({visitors: event.target.value});
    }

    function handleMeetingTypeChange(meetingType: MeetingType) {
        setSettings({meetingType: meetingType});
    }

    const handleParticipantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const newValueNumber = parseInt(event.target.value);

        const isNumberValidInput = Number.isInteger(newValueNumber);
        const newData: Partial<MeetingRoomBookingSettingsData> = {participantNumber: isNumberValidInput ? newValueNumber : null}
        setSettings(newData);
    }

    const showWarning = maxParticipants !== null
        && settings.participantNumber !== null
        && settings.participantNumber > maxParticipants

    const {t} = useTranslation();
    return (
        <div style={{display: "flex", flexDirection: "row"}} data-testid={"time-window-table"}>
            <TableContainer sx={{
                minWidth: "300px",
                maxHeight: "500px", // increased for visitors field, TODO check if needed
                borderRadius: borders.mediumBorderRadius,
                overflow: "auto"
            }}>
                <Table stickyHeader>
                    {!isNoFullscreen &&
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}
                                           sx={{fontSize: "large"}}>
                                    {t('multibookingdialog_meetingroom_settings_title')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody data-testid={"time-window-table-body"}>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_meeting_name"}>
                                <TextField style={{width: "100%", backgroundColor: "white"}}
                                           label={t('multibookingdialog_meetingroom_settings_meeting_name')}
                                           variant="filled" required value={settings.meetingName}
                                           onChange={handleMeetingNameChange}
                                           inputProps={{maxLength: 30}}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_participant_number"}>
                                <TextField style={{width: "100%", backgroundColor: "white"}}
                                           label={t('multibookingdialog_meetingroom_settings_participant_number')}
                                           variant="filled"
                                           value={settings.participantNumber ?? ""}
                                           helperText={showWarning ? t("multibookingdialog_meetingroom_settings_participant_number_recommendation", {
                                               capacity: maxParticipants
                                           }) : ""}
                                           onChange={handleParticipantChange} required/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_dropdown"}>
                                <MeetingRoomBookingDropdown meetingTypeState={settings.meetingType}
                                                            setMeetingTypeState={handleMeetingTypeChange}/>
                            </TableCell>
                        </TableRow>
                        {meetingRoomType === MeetingRoomType.VISITORS_CENTER ?
                            <TableRow>
                                <TableCell align={'center'} colSpan={2} width={1}>
                                    <TextField style={{width: "100%", backgroundColor: "white"}}
                                               label={t("general_visitors")}
                                               variant="filled" value={settings.visitors}
                                               onChange={handleVisitorsChange}
                                               inputProps={{maxLength: 100}}/>
                                </TableCell>
                            </TableRow> : null
                        }
                        {seatConfigs && (
                            <TableRow>
                                <TableCell align={'center'} colSpan={2} width={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-label">Select an Option</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            value={selectedOption}
                                            onChange={handleChange}
                                            SelectDisplayProps={{
                                                style: {
                                                    display: 'flex'
                                                }
                                            }}
                                        >
                                            <MenuItem value="no-change">
                                                <ListItemIcon sx={{
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}></ListItemIcon>
                                                <ListItemText sx={{ textAlign: 'left' }}>
                                                    No change
                                                </ListItemText>
                                            </MenuItem>
                                            {seatConfigs?.map((menuItem, index) => {
                                                return <MenuItem key={index} value={menuItem.name} disabled={disableMenuItem(menuItem)}>
                                                    <ListItemIcon sx={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        {menuItem?.image &&
                                                            <img
                                                                src={menuItem.image}
                                                                style={{width: 24, height: 24}}
                                                            />
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        sx={{
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        {menuItem.name}
                                                    </ListItemText>
                                                </MenuItem>
                                            })}
                                        </Select>
                                        {isDaysInAdvanceNotPossible && (
                                            <FormHelperText error={isDaysInAdvanceNotPossible}>
                                                {t("seat-config-days-in-advance-message", {days: currentSeatConfig?.daysInAdvance})}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    {currentSeatConfig?.image && (
                                        <ImagePreviewDialog imagePath={currentSeatConfig.image} />
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>);
}

export default MeetingRoomBookingSettings
