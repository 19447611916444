import {Box, Button, IconButton, makeStyles, Paper, TextField, Tooltip} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import React, {useEffect, useRef, useState} from "react";
import materna_theme from "../../styles/materna_theme";
import {MeetingRoomSeatConfig} from "../../API";
import {v4 as uuidv4} from "uuid";
import {useTranslation} from "react-i18next";
import {useFileUpload} from "../../hooks/useFileUpload";
import {useMeetingRoomSeatConfigurations} from "../../hooks/useMeetingRoomSeatConfigurations";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {Stack, styled} from "@mui/material";
import {UploadFile} from "@mui/icons-material";

interface SeatConfiguration {
    meetingRoomSeatConfId: string | undefined,
    buildingId: string,
    roomId: string,
    meetingRoomId: string,
    name?: string,
    imageId?: string | null,
    daysInAdvance?: number,
};

interface Props {
    meetingRoomId: string,
    buildingId: string | null | undefined,
    roomId: string,
    selectedConfiguration: MeetingRoomSeatConfig | undefined
    showCreateSeatConfiguration: boolean
    setShowCreateSeatConfiguration: (value: boolean) => void
}

const getSeatConfiguration = (selectedConfiguration: MeetingRoomSeatConfig | undefined, props: Props): SeatConfiguration => {
    return {
        meetingRoomSeatConfId: selectedConfiguration?.meetingRoomSeatConfId,
        buildingId: selectedConfiguration?.buildingId || props.buildingId || "",
        roomId: selectedConfiguration?.roomId || props.roomId || "",
        meetingRoomId: selectedConfiguration?.meetingRoomId || props.meetingRoomId || "",
        name: selectedConfiguration?.name || "",
        imageId: selectedConfiguration?.imageId || "",
        daysInAdvance: selectedConfiguration?.daysInAdvance || 0
    }
}

const CreateOrUpdateMeetingRoomSeatConfiguration: React.FC<Props> = (props) => {
    const {
        selectedConfiguration,
        showCreateSeatConfiguration,
        setShowCreateSeatConfiguration,
    } = props;

    const {uploadImage, getImageUrl} = useFileUpload();

    const [seatConfiguration, setSeatConfiguration] = useState(getSeatConfiguration(selectedConfiguration, props));
    const [imageUrl, setImageUrl] = useState<string|undefined>(undefined);
    useEffect(() => {
        if (seatConfiguration?.imageId) {
            getImageUrl(seatConfiguration.roomId, seatConfiguration.imageId)
                .then((url) => setImageUrl(url || ""))
    }}, [seatConfiguration?.imageId]);
    // to focus on first input
    const nameInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (nameInputRef?.current !== null) {
            nameInputRef.current.focus();
        }
    }, [])

    const {createSeatConfig, updateSeatConfig} = useMeetingRoomSeatConfigurations();

    const {t} = useTranslation();
    const useStyles = makeStyles({
        btnEnabled: {
            color: "green"
        },
        btnDisabled: {
            color: "disabled"
        },
        txtName: {
            [materna_theme.breakpoints.up('xs')]: {
                width: "40%",
            },
            [materna_theme.breakpoints.up('lg')]: {
                width: "40%",
            },
            [materna_theme.breakpoints.up('xl')]: {
                width: "65%",
            },
        },
        scaleFactorInput: {
            width: "6rem",
            marginLeft: "10px"
        }
    });
    const classes = useStyles();


    // maybe we need to allow after image or name
    const isSaveButtonEnabled = true;

    const handleUpdateSeatConfiguration = () => {
        updateSeatConfig({
            meetingRoomSeatConfId: seatConfiguration?.meetingRoomSeatConfId,
            name: seatConfiguration?.name,
            imageId: seatConfiguration?.imageId,
            daysInAdvance: seatConfiguration?.daysInAdvance
        });
    }

    const handleCreateSeatConfiguration = () => {
        createSeatConfig({
            meetingRoomSeatConfId: uuidv4(),
            buildingId: props.buildingId,
            roomId: props.roomId,
            meetingRoomId: props.meetingRoomId,
            name: seatConfiguration?.name,
            imageId: seatConfiguration?.imageId,
            daysInAdvance: seatConfiguration?.daysInAdvance
        })
    }

    const handleChangeSeatConfName = (seatConfName: string) => {
        setSeatConfiguration({...seatConfiguration, name: seatConfName})
    }

    const handleChangeSeatConfDays = (days: number) => {
        setSeatConfiguration({...seatConfiguration, daysInAdvance: days})
    }

    const handleChangeSeatConfImage = async (image: File | undefined) => {
        if (image === undefined) {
            return
        }
        const imageId = await uploadImage(props.roomId, image)
        setSeatConfiguration({...seatConfiguration, imageId: imageId})
    }

    const handleSave = async () => {
        if (!!selectedConfiguration) {
            handleUpdateSeatConfiguration()
        } else {
            handleCreateSeatConfiguration()
        }
        setShowCreateSeatConfiguration(false);
    }

    const StyledSeatConfigButton = styled(Button)({
        width: "32px",
        minWidth: "0",
        '& .MuiButton-startIcon': {
            margin: 0,
        },
    });


    return ( <Paper style={{
      marginTop: "0.625rem",
      padding: "0.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
  }} data-testid={"createRoomComponent"}>
      <Box style={{
          paddingTop: "1rem",
          display: "flex",
          justifyContent: "flex-start",
          width: "74%"
      }}>
          <TextField
              className={classes.txtName}
              style={{marginRight: "10px", width: "60%"}}
              label={t('meeting-room-seatconf-name')}
              value={seatConfiguration?.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeSeatConfName(e.target.value)}
              inputRef={nameInputRef}
              inputProps={{
                  "data-testid": "seatConfiguration-name"
              }}/>

          <TextField
              className={classes.txtName}
              style={{width: "20%", margin: "10px"}}
              label={t('meeting-room-seatconf-daysadvance')}
              value={seatConfiguration?.daysInAdvance}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeSeatConfDays(+e.target.value)}
              inputProps={{
                  "data-testid": "seatConfiguration-book-in-advance"
          }}/>

          <input
              accept="image/*"
              style={{display: "none"}}
              id="contained-image-file"
              type="file"
              onChange={async (event) => {
                    if (event.target.files) {
                        const image = event.target.files[0];
                        handleChangeSeatConfImage(image);
                    }
              }}
          />

          <Stack
              direction="column"
              spacing={1}
              sx={{
                  justifyContent: "center",
                  alignItems: "center",
              }}>
                  {imageUrl &&
                      <label htmlFor="contained-image-file">
                           <img src={imageUrl}
                                alt="Seat configuration image"
                                style={{
                                    width: "60px",
                                    maxHeight: "60px",
                                    height: "auto",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                    border: "1px solid lightgray"
                                }}
                           />
                      </label>
                  }
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <label htmlFor="contained-image-file">
                        <Tooltip title="Upload Image">
                            <StyledSeatConfigButton variant="contained" color="primary" startIcon={<UploadFile/>} component="span"/>
                        </Tooltip>
                    </label>
                    {imageUrl &&
                        <Tooltip title="Delete Image">
                            <StyledSeatConfigButton variant="contained" color="primary" startIcon={<DeleteForeverIcon/>} onClick={e => {
                                setSeatConfiguration({...seatConfiguration, imageId: null})
                                setImageUrl(undefined)
                            }}/>
                        </Tooltip>
                    }
                </Stack>
          </Stack>
      </Box>
      <Box style={{
          paddingTop: "1rem",
          display: "flex",
          justifyContent: "flex-end",
          width: "25%"
      }}>
          <Tooltip title={t('confirm_dialog_ok_button-text')}>
            <span>
                <IconButton onClick={handleSave} disabled={!isSaveButtonEnabled} data-testid={"save-btn"}>
                <SaveIcon className={isSaveButtonEnabled ? classes.btnEnabled : classes.btnDisabled}
                          style={{cursor: "pointer"}} fontSize="large"/>
                </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('cancel')}>
              <IconButton onClick={() => setShowCreateSeatConfiguration(false)} data-testid={"cancel-btn"}>
                  <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"/>
              </IconButton>
          </Tooltip>
      </Box>
  </Paper>);
}
export default CreateOrUpdateMeetingRoomSeatConfiguration
